import {CalcInput} from "./calc.input.model";
import {CalcOutput} from "./calc.output.model";
import {Message} from "./message.model";

/**
 * Yohannes this will be your main class that I will instantiate, please add your functions as needed
 * Each calculation step can use CalcInput params and write to CalcOutput params
 */
export class CalculationService {

  /**
   * @param input
   * @param output
   * @private
   */
  static d_ten_def = 16; // Default bottom bar size
  static d_com_def = 12; // Default top bar size
  static span_pos = 3; //Number of positions per span at which forces are calculated.


  ///***************** SETUP INITIAL INPUT VALUES ***************************

  //Determine loads and properties
  public getLoads(input: CalcInput, output: CalcOutput) {
    //Area of concrete beam (mm2/beam)
    output.Acbeam = input.b_f * input.h_f + 0.5 * (input.b_b + input.b_bdi) * input.h_b + 0.5 * (input.b_bdo * 2 + input.b_delta) * input.h_VP;
    //Total area of steel of beam (mm2/beam)
    output.Asdtot = input.Asb + input.AsCeiling + input.AsVoid;
    //Permanent load of deck
    output.g_deck = (output.Acbeam * input.concretedens + output.Asdtot * 77E-6) * 1000 / input.b_f; //Density of steel taken as 77 kN/m3
    //Total permanent load
    output.g_total = input.g_additional + output.g_deck;
    //ULS load
    output.w_u = input.ilulsf * (input.q_additional + input.q_occupancy) + input.plulsf * output.g_total;
    //SLS load
    output.w_s = input.ilslsf * (input.q_additional + input.q_occupancy) + input.plslsf * output.g_total;
    //Fire loads
    output.w_f = 1.0 * (input.q_additional + input.q_occupancy) + 0.5 * output.g_total;

    return true;
  }


  // **************** FIRE CALCULATIONS

  //Calculate the temperature of a standard ISO 834 fire
  //Input: Standard fire time [min]
  private stdFireTemp(t_fire: number): number {

    //If fire time less than zero set to zero
    if (t_fire < 0) {
      t_fire = 0;
    } else if (t_fire > 600) {
      //If fire time greater than 10 hours set to 10 hours
      t_fire = 600;
    }

    return (20 + 345 * Math.log(8 * t_fire + 1) / Math.LN10);
  }

  /**
   * Calculate the reduction factor for steelwork based on EN 3-1-2 simplified equation
   * Input: Temperature [degC]
   *
   * @param temp
   * @private
   */
  private steel_fy_fact(temp: number): number {
    temp = Math.min(1200, temp); //Do not let temp exceed 1200 degC
    temp = Math.max(20, temp); // Do not let temp be lower than 20 degC
    let kyT = Math.pow(0.9674 * (1 + Math.exp((temp - 482) / 39.19)), -1 / 3.833);
    return Math.min(1.0, kyT);
  }

  /**
   * Calculation of rebar temperature using Wickstrom's temperature
   * Equations based on Buchanan & Abu Section 7.4.2
   * Assumed that 2D heat transfer in a rectangular beam applies
   * Input: Fire resistance rating [min], Distance from side [mm]
   *
   * @param t_fire
   * @param distside_x
   * @param distside_y
   * @private
   */
  private calcRebarTemp2D(t_fire: number, distside_x: number, distside_y: number): number {

    if (t_fire < 30) { //For fire times less than 30 minutes ignore
      return 20;
    } else {
      distside_x = Math.max(1, distside_x); //Set distance from side to 1mm as minimum, otherwise errors in calculations occur
      distside_y = Math.max(1, distside_y); //Set distance from side to 1mm as minimum, otherwise errors in calculations occur
      let T_fire = this.stdFireTemp(t_fire);
      let Eta_w = 1 - 0.0616 * Math.pow(t_fire / 60, -0.88);
      let Eta_x = 0.18 * Math.log(t_fire / 60 / Math.pow(distside_x / 1000, 2)) - 0.81;
      let Eta_y = 0.18 * Math.log(t_fire / 60 / Math.pow(distside_y / 1000, 2)) - 0.81;
      let temp = T_fire * (Eta_w * (Eta_x + Eta_y - 2 * Eta_x * Eta_y) + Eta_x * Eta_y);

      temp = Math.max(20, temp); //Ensure temperature not below 20 degC
      temp = Math.min(1200, temp); //Ensure temperature not above 1200 degC

      return temp;
    }
  }

  /**
   * Check if minimum thickness of flange is sufficient for required fire resistance
   *
   * @param t_fire
   * @param h_f
   * @param err_string
   * @private
   */
  private h_ffiremin(
    t_fire: number,       //Fire resistance (min)
    h_f: number,          //Height of flange (mm)
    err_string: Message[]  //Error string to which a message is added as needed
  ) {
    const t_rating = [0, 30, 60, 90, 120]; //Fire resistance rating
    const h_fmin = [0, 50, 70, 85, 100]; //Minimum thickness of flange

    //Fire resistance ratings greater than 2 hours not considered
    if (t_fire > 120) {

      err_string.push({comment: 'NOTE: Fire resistance > 120min. Consult specialist literature.'});
    } //if
    //Check if a fire rating is needed
    else if (t_fire > 0) {
      for (let i = 1; i < t_rating.length; i++) {
        if (t_fire <= t_rating[i] && t_fire > t_rating[i - 1]) {
          if (h_f < h_fmin[i]) { //Flange too thin for insulation fire resistance rating
            let msg = 'Error: Fire resistance. Minimum flange thickness: ' + h_fmin[i] + ' mm. (Note that a screed can be added to provide thickness.)';
            err_string.push({error: msg});
          }
        }
      }//for i
    }//if

    return true;
  }//h_ffiremin


  /**
   * Area of steel needed for an applied fire limit state force
   * Input: Mf - FLS moment [Nmm], d - Effective depth [mm], fcu - Conc. cube strength [MPa],
   * b - Breadth [mm], fyr - Rebar fy [MPa] steel temp [degC], af_max - Max. comp block depth [mm]
   *
   * @param Mf
   * @param d
   * @param fcu
   * @param b
   * @param fyr
   * @param steeltemp
   * @param af_max
   * @param err_string
   * @constructor
   * @private
   */
  private As_fire(Mf: number, d: number, fcu: number, b: number, fyr: number, steeltemp: number, af_max: number, err_string: Message[]): number {

    let af = +d - Math.sqrt(Math.pow(d, 2) - 2 * Mf / 0.87 / 0.8 / fcu / b); //Depth of compression block

    if (af > af_max) { // Max compression depth exceeded
      return -1;
    } else {
      let Kysteel = this.steel_fy_fact(steeltemp);
      let As = af * b * 0.87 * 0.8 * fcu / fyr / Kysteel;
      return As;
    }
  } //End function

  /**
   * Calculate the depth of an isotherm for bending calculations
   *
   * @param t_fire
   * @param Temp_iso
   * @private
   */
  private depthTempIso(t_fire: number, Temp_iso: number) { //Fire resistance time in minutes

    let Tf = this.stdFireTemp(t_fire); // Temperature of the fire
    let Eta_w = 1 - 0.0616 * Math.pow(t_fire / 60, -0.88); //Eta_w
    let Eta_x = Temp_iso / Tf / Eta_w;
    let step1 = Math.exp((Eta_x + 0.81) / 0.18);
    return Math.sqrt(t_fire / 60 / step1) * 1000;

  }

  /**
   * Determine fire rebar required throughout the beam length
   *
   * @param input
   * @param output
   * @constructor
   * @private
   */
  private AsfireAll(input: CalcInput, output: CalcOutput) {

    let span_pos = CalculationService.span_pos; // 3 per span
    let As_fire = [] as number[];
    let d500 = this.depthTempIso(input.fireresist, 500);

    //console.log(output.Mf);

    for (let i = 0; i < output.Mf.length; i++) {
      //Initiate with default values for sagging, and update below for the end of spans
      let b_fire = input.b_b;
      let d_eff = output.d_eff[i];
      let axisdist = input.cover + CalculationService.d_ten_def / 2;
      let sidedist = 1000;
      let Trebar = this.calcRebarTemp2D(input.fireresist, axisdist, 1000);
      let af_max = input.h_f - d500;

      if (i % span_pos != 1) {//At ends of each span
        b_fire = input.b_b - 2 * d500; //Reduce for 2-sided fire exposure
        d_eff = d_eff - d500; // Reduce for bottom fire damaged area
        sidedist = axisdist;
        af_max = input.h - d500;
      }

      Trebar = this.calcRebarTemp2D(input.fireresist, axisdist, sidedist);
      let Asf = this.As_fire(Math.abs(output.Mf[i] * 1E6), d_eff, input.fcu, b_fire, input.fyr, Trebar, af_max, output.messages);
      if (Asf == undefined || Asf < 0) {
        As_fire.push(0);
        output.messages.push({error: 'Error: Fire resistance - insufficient capacity. Span: ' + (Math.round(i / span_pos) + 1)});
      } else {
        As_fire.push(Asf);
      }//if
    }

    return As_fire;
  } //Asfireall


  /// ***************** LOADS AND SPECIFICATIONS **********************

  /**
   * Set effective depth for spans at different positions
   *
   * @param h
   * @param cover
   * @param numspan
   * @private
   */
  private calcd_eff(h: number, cover: number, numspan: number) {
    let d_eff = [] as number[];

    let d_eff_sag = h - cover - CalculationService.d_ten_def / 2; //Sagging effective depth
    let d_eff_hog = h - cover - CalculationService.d_com_def / 2; //Hogging effective depth
    let span_pos = CalculationService.span_pos;

    for (let i = 0; i < numspan; i++) {
      d_eff[i * span_pos + 0] = d_eff_hog; //Hogging
      d_eff[i * span_pos + 1] = d_eff_sag; //Sagging
      d_eff[i * span_pos + 2] = d_eff_hog; //Hogging
    }

    return d_eff;
  }

  /**
   * Set effective breadth of area in compression for bending calculations
   *
   * @param b_f
   * @param b_b
   * @param numspan
   * @private
   */
  private b_effcomp(b_f: number, b_b: number, numspan: number) {
    let span_pos = CalculationService.span_pos;
    let b_eff = [] as number[];

    for (let i = 0; i < numspan; i++) {
      b_eff[i * span_pos + 0] = b_b; //Hogging
      b_eff[i * span_pos + 1] = b_f; //Sagging
      b_eff[i * span_pos + 2] = b_b; //Hogging
    }//for i

    return b_eff;
  }//beffcomp


  /**
   * Determine moments in a multi-span beam
   *
   * @param noSpans
   * @param span
   * @param w_u
   * @param err_string
   * @private
   */
  private calcMoments(
    noSpans: number,  //Number of spans
    span: number,      //Span (m)
    w_u: number,        //Distributed load (kN/m)
    err_string: Message[]) {  //Error string

    /*
    Determination of moments in a multi-span beam at end and mid-span.
    Calculations based on Table 4 of SANS 10100-1 for 3 or more beams.
    Calculations based on first principles for 1 and 2 span beams.
    Outputs: Mu.*/

    let span_pos = CalculationService.span_pos;
    let Mu = [] as number [];
    if (noSpans < 1 || noSpans > 4) { //Check if the correct number of spans inputted. Only 1-4 can be handled
      err_string.push({error: 'Error: Invalid number of spans selected for calculations.'});
    }

    let momentFact: number[][] = [[0, 1 / 8, 0],                                                   //1 span
      [0, 1 / 11, -1 / 9, -1 / 9, 1 / 11, 0],                                      //2 spans
      [0, 1 / 11, -1 / 9, -1 / 9, 1 / 14, -1 / 9, -1 / 9, 1 / 11, 0],                         //3 spans
      [0, 1 / 11, -1 / 9, -1 / 9, 1 / 14, -1 / 12, -1 / 12, 1 / 14, -1 / 9, -1 / 9, 1 / 11, 0]];               //4 spans

    for (let i = 0; i < momentFact[noSpans - 1].length; i++) {
      Mu.push(w_u * span ** 2 * momentFact[noSpans - 1][i]);
    }

    return Mu;
  }

  /**
   * Determine shear forces in a multi-span beam
   *
   * @param noSpans
   * @param span
   * @param w_u
   * @param err_string
   * @private
   */
  private calcShears(
    noSpans: number,        //Number of spans
    span: number,           //Span length (m)
    w_u: number,            //ULS load (kN/m)
    err_string: Message[]) {   //Error string

    /*
      Calculations based on Table 4 of SANS 10100-1 for 3 or more beams.
      Calculations based on first principles for 1 and 2 span beams.
      Outputs: Vu array.*/

    let Vu = [] as number[];
    if (noSpans < 1 || noSpans > 4) { //Check if the correct number of spans inputted. Only 1-4 can be handled
      err_string.push({error: 'Shear calculations: Invalid number of spans.'})
    }

    const VFact = [[0.50, 0.0, -0.50],                                          //1 span
      [0.42, 0.0, -0.61, 0.61, 0.0, -0.42],                             //2 spans
      [0.45, 0.0, -0.60, 0.55, 0.0, -0.55, 0.60, 0.0, -0.45],                //3 spans
      [0.45, 0.0, -0.60, 0.55, 0.0, -0.55, 0.55, 0.0, -0.55, 0.60, 0.0, -0.45]];  //4 spans

    for (let i = 0; i < VFact[noSpans - 1].length; i++) {
      Vu.push(w_u * span * VFact[noSpans - 1][i])
    }

    return Vu;
  }

  /**
   *  Determine shear forces at the face of support in a multi-span beam
   *
   * @param numspans
   * @param Vu
   * @param w_u
   * @param d_eff
   * @param suppwidth
   * @private
   */
  private calcShearsAtD(
    numspans: number,     //Number of span
    Vu: number [],        //Applied shear force
    w_u: number,          //ULS distributed load (kN/m)
    d_eff: number[],      //Effective depth (mm)
    suppwidth: number) {  //Support width

    let span_pos = CalculationService.span_pos;
    let Vu_d = [] as number[];
    for (let i = 0; i < numspans; i++) {
      Vu_d.push(Vu[i * span_pos + 0] - w_u * (0.5 * suppwidth + d_eff[i]) / 1000);
      Vu_d.push(0); //Midspan
      Vu_d.push(Vu[i * span_pos + 2] + w_u * (0.5 * suppwidth + d_eff[i]) / 1000);
    }//for i
    return Vu_d;
  }

  /// ***************** ULS CALCULATIONS **********************

  /**
   * Area of steel needed for a beam to SANS 10100-1
   *
   * @param Mu
   * @param bf
   * @param bw
   * @param hf
   * @param d
   * @param fcu
   * @param fy
   * @param Beta_b
   * @param err_string
   * @constructor
   * @private
   */
  private AsMrbeam_SANS( // This property has no use for the rest of the calculation, it has been updated in the calcMrRebar.
    Mu: number,     //ULS moment (Nmm)
    bf: number,     //Flange breadth (mm)
    bw: number,     //Web breadth (mm)
    hf: number,     //Height of flange (mm)
    d: number,      //Effective depth (mm)
    fcu: number,    //Concrete strength (MPa)
    fy: number,     //Steel yield strength (MPa)
    Beta_b: number = 1.0, //Moment redistribution factor
    err_string: Message[]): number {  //Error string for feedback

    //Output: Area of steel required for applied moment. Value is -1 if simplified design not suitable.

    let K = Mu / bf / d ** 2 / fcu;
    let As = 0; //Area of steel

    let Kdash = 0.156; //No redistribution
    if (Math.abs(Beta_b - 1.0) > 0.1) { //Allowing for moment redistribution. Calculations assume minimum 10% moment reduction
      Kdash = 0.402 * (Beta_b - 0.4) - 0.18 * (Beta_b - 0.4) ** 2;
    }

    //For rectangular beams
    if (K <= Kdash) { //only tension steel required
      let Beta_f = 0.45 * hf / d * (1 - bw / bf) * (1 - hf / d) + 0.15 * bw / bf; //Beta_b factor for Table 5
      let Mlimit = Beta_f * fcu * bf * d * d;

      let z = Math.min((0.5 + (0.25 - K / 0.9) ** (0.5)) * d, 0.95 * d); //limit lever arm to 0.95d check
      let x = (d - z) / 0.45;
      //console.log(z, x, hf, Mlimit, K, Kdash);
      if (x < hf) { //Neutral axis lies within the flange
        As = Mu / 0.87 / fy / z;
      } else if (hf < 0.45 * d && Mu < Mlimit && Beta_b >= 0.9) { //Neutral axis lies with web and requirements within limits
        As = (Mu + 0.1 * fcu * bw * d * (0.45 * d - hf)) / 0.87 / fy / (d - 0.5 * hf);
      } else {
        err_string.push({error: 'Error: ULS design. Mu exceeds simplified calculations for Mr. Detailed analysis needed.'});
      }

    } else { //tension and compression steel required
      err_string.push({error: 'Error: ULS design. Compression steel required.'});
    }

    return As;
  }

  /**
   * Calculated of ULS bending steel at all positions
   *
   * @param input
   * @param output
   * @constructor
   * @private
   */
  private AsULSall(input: CalcInput, output: CalcOutput) {
    let Asu = [] as number[]; //Array of ULS steel area to be filled

    let bfinv = 1000 / input.b_f as number; //Inverse of breadth of flange for multiplying by widths

    for (let i = 0; i < output.Mu.length; i++) {
      let h_f = input.h_f; // Depth of flange for sagging
      let Beta_b = 1.1;   //Assuming 10% sagging redistribution
      if ((i % CalculationService.span_pos) != 1) {
        h_f = input.h; //Full height of web for hogging
        Beta_b = 0.9;
      }
      Asu.push(this.AsMrbeam_SANS(Math.abs(output.Mu[i]) * 1E6, output.b_comp[i] * bfinv, output.b_wt[i] * bfinv,
        h_f, output.d_eff[i], input.fcu, input.fyr, Beta_b, output.messages));

    }//for i

    return Asu;
  }//AsULSall


  //**************** SLS DESIGN *************************

  /**
   * Default span-depth ratios for multi-span beams from SANS 10100-1 Table 10
   *
   * @param noSpans
   * @param err_string
   * @private
   */
  private defaultSpanDepth(
    noSpans: number,         //Number of spans
    err_string: Message[]) {  //Error string
    /*
    Populate span-depth array with default values from SANS 10100-1 Table 10.
    Output: Populated spanDepth array */

    const spanDepthRatios = [[16],                   //1 span
      [24, 24],               //2 spans
      [24, 28, 24],           //3 spans
      [24, 28, 28, 24]];      //4 spans


    if (noSpans < 1 || noSpans > 4) {
      err_string.push({error: 'Error: Number of spans out of range for span/depth ratio.'});
    }

    return spanDepthRatios[noSpans - 1]
  }

  /**
   * Calculate the steel needed, according to SANS 10100-1, Section 4.3.6
   *
   * @param Mu
   * @param As_req
   * @param d_eff
   * @param b_f
   * @param b_w
   * @param span
   * @param spandepthr
   * @param Gam_g_s
   * @param Gam_q_s
   * @param Gam_g_u
   * @param Gam_q_u
   * @param fy
   * @param Beta_b
   * @param err_string
   * @private
   */
  private calcAs_SLS(
    Mu: number,             //ULS moment
    As_req: number,         //Tension renf't required at mid-span to resist ULS momemts = As_ULS
    d_eff: number,          //Effective depth
    b_f: number,            //Breadth of compression flange
    b_w: number,            //Breadth of web
    span: number,           //Span of beam
    spandepthr: number,     //Span-to-depth ratio
    Gam_g_s: number,        //selweight - SLS
    Gam_q_s: number,        //imposed load - SLS
    Gam_g_u: number,        //selweight - SLS
    Gam_q_u: number,        //imposed load - SLS
    fy: number,             //Steel yield strength
    Beta_b: number,         //Amount of redistribution
    err_string: Message[]) {   //Error string

    let As_prov = 0; // As_SLS

    let TbeamFac = 1.0;
    //Consider T-beam adjustment factor. 0.8 value for most VP configurations.
    if (b_w < 0.3 * b_f) {
      TbeamFac = 0.8;
    } else if (b_w < 1.0 * b_f) {
      TbeamFac = 0.8 + (1.0 - 0.8) / (1.0 - 0.3) / b_f * (b_w - 0.3 * b_f);
    }

    //Required modification factor
    let ModFact_reqd = span / (spandepthr * d_eff) / TbeamFac;

    //Calculate steel area required
    if (ModFact_reqd > 2.0) { // If mod factor required is simply too high
      err_string.push({error: 'Error: SLS check. Slab to shallow for required span.'});
    }

    let fs_reqd = 477 - (ModFact_reqd - 0.55) * 120 * (0.9 + Mu / b_f / d_eff ** 2);

    As_prov = 0.87 * fy * (Gam_g_s + Gam_q_s) / (Gam_g_u + Gam_q_u) * As_req / fs_reqd / Beta_b;

    //console.log(TbeamFac, ModFact_reqd, fs_reqd, As_prov, As_req);

    return As_prov;
  }

  /**
   * Calculate the tension midspan steel needed for all spans
   *
   * @param input
   * @param output
   * @private
   */
  private calcAs_SLSall(input: CalcInput, output: CalcOutput) {

    let As_s = [] as number[];
    //Populate the array of SLS steel required
    for (let i = 0; i < input.numspans; i++) {
      As_s.push(0); //Support
      let j = i * CalculationService.span_pos + 1; //Array position of midspan element, for easy referencing
      As_s.push(this.calcAs_SLS(output.Mu[j] * 1E3, output.Asutotal[j], output.d_eff[j], input.b_f, input.b_b, input.spanlength * 1000, output.spandepthr[i],
        input.plslsf, input.ilslsf, input.plulsf, input.ilulsf, input.fyd, 1.1, output.messages));
      As_s.push(0); //Support
    }
    return As_s;
  }

  ///***************** SHEAR RESISTANCE ****************************

  /**
   * Function to check whether shear resistance is sufficient
   * Generates string array with details
   *
   * @param input
   * @param output
   * @private
   */
  private checkShear_SANS(input: CalcInput, output: CalcOutput) {

    let VCheck = [] as string[];
    let fcuv = Math.min(input.fcu, 40); //Concrete strength limited to 40 MPa for calculations
    let span_pos = CalculationService.span_pos;

    //For each span check the shear stresses at ends to SANS 10100-1 Section 4.3.4
    //Check one beam width at a time.
    for (let i = 0; i < input.numspans; i++) {
      let bf_ratio = input.b_f / 1000;
      let ms = i * span_pos + 1; //midspan array location
      let As_bvd = Math.min(100 * output.Astotal[ms] / input.b_b / output.d_eff[ms], 3);
      let vc = 0.75 / 1.40 * Math.pow(fcuv / 25, 1 / 3) * Math.pow(As_bvd, 1 / 3) * Math.pow(400 / output.d_eff[ms], 0.25); //Shear capacity at d from face
      let vc_enh = Math.min(0.75 * Math.sqrt(fcuv), 4.75);

      let v1atd = Math.abs(output.Vu_atd[i * span_pos + 0] * 1000) * bf_ratio / input.b_b / output.d_eff[ms];
      let v1 = Math.abs(output.Vu    [i * span_pos + 0] * 1000) * bf_ratio / input.b_b / output.d_eff[ms];
      let v3atd = Math.abs(output.Vu_atd[i * span_pos + 2] * 1000) * bf_ratio / input.b_b / output.d_eff[ms];
      let v3 = Math.abs(output.Vu    [i * span_pos + 2] * 1000) * bf_ratio / input.b_b / output.d_eff[ms];

      //Left hand side
      if (v1 > vc_enh) { //Shear at d
        VCheck.push('FAIL');
        output.messages.push({error: 'Error: Enhanced shear fail. Span ' + (i + 1) + ' left. Increase beam width. v = ' + Math.round(v1 * 100) / 100 + 'MPa. vc = ' + Math.round(vc_enh * 100) / 100 + 'MPa.'});
      } else if (v1atd > vc) { //Enhanced shear
        VCheck.push('FAIL');
        output.messages.push({error: 'Error: Shear fail. Span ' + (i + 1) + ' left. Increase beam width. v = ' + Math.round(v1atd * 100) / 100 + 'MPa. vc = ' + Math.round(vc * 100) / 100 + 'MPa.'});
      } else { //OK
        VCheck.push('OK');
      }

      VCheck.push(''); // Keep blank. Shear check not done at midspan.

      //Right hand side
      if (v3 > vc_enh) { //Shear at d
        VCheck.push('FAIL');
        output.messages.push({error: 'Error: Enhanced shear fail. Span ' + (i + 1) + ' right. Increase beam width. v = ' + Math.round(v3 * 100) / 100 + 'MPa. vc = ' + Math.round(vc_enh * 100) / 100 + 'MPa.'});
      } else if (v3atd > vc) { //Enhanced shear
        VCheck.push('FAIL');
        output.messages.push({error: 'Error: Shear fail. Span ' + (i + 1) + ' right. Increase beam width. v = ' + Math.round(v3atd * 100) / 100 + 'MPa. vc = ' + Math.round(vc * 100) / 100 + 'MPa.'});
      } else { //OK
        VCheck.push('OK');
      }
    }

    return VCheck;
  }


  // ***************** PROPPING SPACING IN CONSTRUCTION ***************
  /**
   * Spans are based upon standard construction loads and partial factors
   *
   * @param VoidNo
   * @param BeamNo
   * @param hslab
   * @private
   */
  private deckProp(VoidNo: number, BeamNo: number, hslab: number): number {

    let hpropcalc = [] as number []; //Array of total slab thickness from which propping centres have been calculated
    let propping = [] as number[][]; //Array of propping centres in [m]
    let propspace = 0;

    if (VoidNo == 1) { //VP250
      hpropcalc = [300, 310, 320, 330, 340, 350, 360, 370, 380, 390, 400, 410, 420] //Thickness of slabs considered
      propping = [[2.2, 2.1, 2, 1.9, 1.8, 1.7, 1.7, 1.6, 1.5, 1.5, 1.4, 1.4, 1.4], //VP Beam 150
        [1.8, 1.7, 1.7, 1.6, 1.5, 1.5, 1.4, 1.4, 1.3, 1.3, 1.2, 1.2, 1.2], //VP Beam 225
        [1.6, 1.5, 1.4, 1.4, 1.3, 1.3, 1.2, 1.2, 1.2, 1.1, 1.1, 1.1, 1], //VP Beam 300
        [1.4, 1.3, 1.3, 1.2, 1.2, 1.1, 1.1, 1.1, 1, 1, 1, 0.9, 0.9], //VP Beam 375
        [1.2, 1.2, 1.1, 1.1, 1.1, 1, 1, 1, 0.9, 0.9, 0.9, 0.9, 0.8]]; //VP Beam 450
    } else if (VoidNo == 2) { //VP200
      hpropcalc = [250, 260, 270, 280, 290, 300, 310, 320, 330, 340, 350, 360, 370] //Thickness of slabs considered
      propping = [[2.6, 2.4, 2.3, 2.2, 2.1, 2, 1.9, 1.9, 1.8, 1.7, 1.7, 1.6, 1.6], //VP Beam 150
        [2.2, 2.1, 2, 1.9, 1.8, 1.7, 1.7, 1.6, 1.5, 1.5, 1.4, 1.4, 1.4], //VP Beam 225
        [1.9, 1.8, 1.7, 1.6, 1.6, 1.5, 1.5, 1.4, 1.4, 1.3, 1.3, 1.2, 1.2], //VP Beam 300
        [1.7, 1.6, 1.5, 1.5, 1.4, 1.4, 1.3, 1.3, 1.2, 1.2, 1.1, 1.1, 1.1], //VP Beam 375
        [1.5, 1.4, 1.4, 1.3, 1.3, 1.2, 1.2, 1.1, 1.1, 1.1, 1, 1, 1]]; //VP Beam 450
    } else if (VoidNo == 3) { //VP120
      hpropcalc = [170, 180, 190, 200, 210, 220, 230, 240, 250, 260, 270, 280, 290] //Thickness of slabs considered
      propping = [[3, 3, 3, 3, 3, 3, 3, 2.9, 2.8, 2.7, 2.6, 2.5, 2.4], //VP Beam 150
        [3, 3, 3, 3, 2.8, 2.7, 2.6, 2.5, 2.4, 2.3, 2.2, 2.1, 2.1], //VP Beam 225
        [3, 2.8, 2.7, 2.6, 2.5, 2.4, 2.3, 2.2, 2.1, 2, 1.9, 1.9, 1.8], //VP Beam 300
        [2.6, 2.5, 2.4, 2.3, 2.2, 2.1, 2, 1.9, 1.9, 1.8, 1.7, 1.7, 1.6], //VP Beam 375
        [2.3, 2.2, 2.1, 2, 1.9, 1.9, 1.8, 1.7, 1.7, 1.6, 1.6, 1.5, 1.5]]; //VP Beam 450
    } else if (VoidNo == 4) { //VP75
      hpropcalc = [125, 135, 145, 155, 165, 175, 185, 195, 205, 215, 225, 235, 245] //Thickness of slabs considered
      propping = [[2.7, 2.6, 2.6, 2.5, 2.5, 2.4, 2.4, 2.3, 2.3, 2.3, 2.2, 2.2, 2.2], //VP Beam 150
        [2.7, 2.6, 2.6, 2.5, 2.5, 2.4, 2.4, 2.4, 2.3, 2.3, 2.3, 2.2, 2.2], //VP Beam 225
        [2.7, 2.6, 2.6, 2.5, 2.5, 2.4, 2.4, 2.4, 2.3, 2.3, 2.3, 2.2, 2.2], //VP Beam 300
        [2.6, 2.6, 2.5, 2.5, 2.5, 2.4, 2.4, 2.3, 2.3, 2.3, 2.2, 2.2, 2.2], //VP Beam 375
        [2.6, 2.6, 2.5, 2.5, 2.4, 2.4, 2.3, 2.3, 2.2, 2.2, 2.1, 2, 1.9]]; //VP Beam 450
    } else if (VoidNo == 5) { //VP50 - 300 voids
      hpropcalc = [100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220] //Thickness of slabs considered
      propping = [[2.1, 2.1, 2, 2, 2, 1.9, 1.9, 1.8, 1.8, 1.8, 1.8, 1.7, 1.7], //VP Beam 150
        [2.2, 2.1, 2.1, 2, 2, 2, 1.9, 1.9, 1.9, 1.8, 1.8, 1.8, 1.8], //VP Beam 225
        [2.2, 2.1, 2.1, 2, 2, 2, 1.9, 1.9, 1.9, 1.8, 1.8, 1.8, 1.8], //VP Beam 300
        [2.2, 2.1, 2.1, 2, 2, 2, 1.9, 1.9, 1.9, 1.8, 1.8, 1.8, 1.8], //VP Beam 375
        [2.2, 2.1, 2.1, 2, 2, 2, 1.9, 1.9, 1.9, 1.8, 1.8, 1.8, 1.8]]; //VP Beam 450
    } else if (VoidNo == 6) { //VP50 - 225 voids
      hpropcalc = [100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220] //Thickness of slabs considered
      propping = [[2.2, 2.2, 2.1, 2.1, 2, 2, 2, 1.9, 1.9, 1.9, 1.8, 1.8, 1.8], //VP Beam 150
        [2.2, 2.2, 2.1, 2.1, 2, 2, 2, 1.9, 1.9, 1.9, 1.9, 1.8, 1.8], //VP Beam 225
        [2.2, 2.2, 2.1, 2.1, 2, 2, 2, 1.9, 1.9, 1.9, 1.9, 1.8, 1.8], //VP Beam 300
        [2.2, 2.1, 2.1, 2.1, 2, 2, 2, 1.9, 1.9, 1.9, 1.8, 1.8, 1.8], //VP Beam 375
        [2.2, 2.1, 2.1, 2, 2, 2, 1.9, 1.9, 1.9, 1.9, 1.8, 1.8, 1.8]]; //VP Beam 450
    } else if (VoidNo == 7) { //VP50 - No voids
      hpropcalc = [100, 110, 120, 130, 140, 150, 160, 170, 180, 190, 200, 210, 220] //Thickness of slabs considered
      propping = [[1.9, 1.8, 1.8, 1.8, 1.8, 1.7, 1.7, 1.7, 1.7, 1.7, 1.6, 1.6, 1.6], //VP Beam 150
        [1.7, 1.7, 1.7, 1.6, 1.6, 1.6, 1.6, 1.6, 1.5, 1.5, 1.5, 1.5, 1.5], //VP Beam 225
        [1.6, 1.6, 1.6, 1.6, 1.5, 1.5, 1.5, 1.5, 1.5, 1.4, 1.4, 1.4, 1.4], //VP Beam 300
        [1.6, 1.5, 1.5, 1.5, 1.5, 1.5, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4], //VP Beam 375
        [1.5, 1.5, 1.5, 1.5, 1.4, 1.4, 1.4, 1.4, 1.4, 1.4, 1.3, 1.3, 1.3]]; //VP Beam 450
    } else { //Incorrect number selected
      //return "Incorrect void selection for propping calculation.";
    }

    let foundsection = false;
    for (let i = 0; i < hpropcalc.length; i++) { // Look for slab thickness matching the inputted value
      if (hslab <= hpropcalc[i] && foundsection == false) { //Slab thickness found which is just greater than specified input thickness
        propspace = (propping[BeamNo - 1][i]); //Convert to mm for consistency
        foundsection = true;
      }
    }//for i

    //If no propping layout provided then return error
    if (foundsection == false) {
      propspace = 0; //false; //"No propping data found. Consult design engineers for propping design.";
    }

    propspace = 2;

    return propspace;
  }//End deckProp

  /// ************************* SELECT AND FINALISE REBAR ********************************

  /**
   * Minimum rebar required for bending calculations
   *
   * @param input
   * @param output
   * @constructor
   * @private
   */
  private As_min(input: CalcInput, output: CalcOutput) {

    let AsMin = [] as number [];
    let AsMweb = 0.0013 * input.b_b * input.h as number; //Minimum web steel for tension: 0.0013 * bw * h

    //Web in tension
    if (input.b_b / input.b_f < 0.4) {
      AsMweb = 0.0018 * input.b_b * input.h;
    } // 0.0018 * bw * h

    //Flange in tension
    let AsMfl = 0.0020 * input.b_b * input.h as number; //Minimum flange steel for tension

    let span_pos = CalculationService.span_pos;
    for (let i = 0; i < input.numspans; i++) {
      AsMin.push(AsMweb, AsMfl, AsMweb);
    }

    return AsMin;
  }// As_Min

  /**
   * Select final rebar for design
   *
   * @param input
   * @param output
   * @constructor
   * @private
   */
  private As_final(input: CalcInput, output: CalcOutput) {

    //Run through rebar options and see required total rebar to the maximum. Setup the string indicating which limit state governs.
    for (let i = 0; i < output.Asftotal.length; i++) {
      output.Astotal.push(output.Asutotal[i]); //Total rebar
      output.AsGovFactor.push('ULS');
      let span_pos = CalculationService.span_pos;

      //Check SLS only for midspan
      if (output.Astotal[i] < output.Asstotal[i] && (i % 3) == 1) {
        output.Astotal[i] = output.Asstotal[i]
        output.AsGovFactor[i] = 'SLS';
      }

      //Check fire limit rebar at all positions
      if (output.Astotal[i] < output.Asftotal[i]) {
        output.Astotal[i] = output.Asftotal[i]
        output.AsGovFactor[i] = 'Fire';
      }

      //Check if minimum steel required
      if (output.Astotal[i] < output.Asmintotal[i]) {
        output.Astotal[i] = output.Asmintotal[i];
        output.AsGovFactor[i] = 'Min';
      }

      //Check if max steel exceeded
      if (output.Astotal[i] > 0.04 * input.b_f * input.h) {
        output.AsGovFactor[i] = 'Max exceeded!';

        output.messages.push({error: 'Error: Max steel exceeded. Span ' + Math.floor(i / span_pos)});
      }

    }//for i

    return true;
  }

  /**
   * Determine rebar area based on contribution of deck to carrying capacity
   *
   * @param input
   * @param output
   * @private
   */
  private calcAsr(input: CalcInput, output: CalcOutput) {

    let Asr = [] as number[];
    let span_pos = CalculationService.span_pos;

    for (let i = 0; i < input.numspans; i++) {
      Asr.push(output.Astotal[i * span_pos + 0]);
      Asr.push(Math.max(0, output.Astotal[i * span_pos + 1] - input.AstVP * input.fyd / input.fyr)); //Subtract area of VP tension decking, but do not let be less than zero
      Asr.push(output.Astotal[i * span_pos + 2]);
      //console.log(i, output.Astotal[i * span_pos + 0], output.Astotal[i * span_pos + 1], output.Astotal[i * span_pos + 2], input.AstVP);
    }//for i

    return Asr;
  }//calcAsr

  /**
   * Slab rebar (mm2/m) - Select a possible rebar configuration
   *
   * @param Asr
   * @private
   */
  private slabRebar(Asr: number) {
    let rebar = '' as string;
    let foundrebar = false;

    let bar = [10, 10, 10, 10, 10, 10, 10, 10, 10, 10, 12, 12, 10, 12, 10, 12, 12,
      10, 16, 16, 12, 16, 12, 16, 16, 20, 16, 20, 16, 20, 25, 20, 25, 20, 25, 25, 32, 20, 32, 25, 32, 32, 25, 32, 32];
    let spacing = [375, 375, 350, 325, 300, 275, 250, 225, 200, 175, 250, 225, 150, 200, 125, 175, 150,
      100, 250, 225, 125, 200, 100, 175, 150, 200, 125, 175, 100, 150, 200, 125, 175, 100, 150, 125, 200, 75, 175, 100, 150, 125, 75, 100, 75];
    let As = [224.4, 209.4, 224.4, 241.7, 261.8, 285.6, 314.2, 349.1, 392.7, 448.8, 452.4, 502.7, 523.6, 565.5,
      628.3, 646.3, 754, 785.4, 804.2, 893.6, 904.8, 1005.3, 1131, 1148.9, 1340.4, 1570.8, 1608.5, 1795.2, 2010.6,
      2094.4, 2454.4, 2513.3, 2805, 3141.6, 3272.5, 3927, 4021.2, 4188.8, 4595.7, 4908.7, 5361.7, 6434, 6545, 8042.5, 10723.3];


    for (let i = 0; i < As.length; i++) {
      if (As[i] > Asr && foundrebar == false) {
        rebar = 'Y' + bar[i] + '-' + spacing[i];
        foundrebar = true;
      }//if
    }//for i

    if (foundrebar == true) {
      return rebar;
    } else {
      return 'Not found';
    }
  }

  /**
   * Beam rebar (No bars / beam) - Select possible beam configuration
   *
   * @param Asr
   * @private
   */
  private beamRebar(Asr: number) {
    let rebar = '' as string;
    let foundrebar = false as boolean;

    let bar = [0, 10, 12, 10, 16, 12, 10, 10, 20, 12, 16, 12, 25, 16, 20, 16, 32, 20, 25, 20, 25, 32, 25, 32, 32];
    let no = [0, 1, 1, 2, 1, 2, 3, 4, 1, 3, 2, 4, 1, 3, 2, 4, 1, 3, 2, 4, 3, 2, 4, 3, 4];
    let As = [0, 157.1, 113.1, 157.1, 201.1, 226.2, 235.6, 314.2, 314.2, 339.3, 402.1, 452.4, 490.9, 603.2,
      628.3, 804.2, 804.2, 942.5, 981.7, 1256.6, 1472.6, 1608.5, 1963.5, 2412.7, 3217];

    //If no rebar required
    if (Asr <= 1) { //
      rebar = 'None';
      foundrebar = true;
    }//if
    else {
      for (let i = 0; i < As.length; i++) {
        if (As[i] > Asr && foundrebar == false) {
          rebar = no[i] + 'Y' + bar[i] + '/beam';
          foundrebar = true;
        }//if
      }//for i
    }//else

    if (foundrebar == true) {
      return rebar;
    } else {
      return 'Not found';
    }
  }

  /**
   * Select a possible rebar option for designers
   *
   * @param input
   * @param output
   * @private
   */
  private selectRebar(input: CalcInput, output: CalcOutput) {
    let rebar = [] as string[];
    let span_pos = CalculationService.span_pos;

    for (let i = 0; i < input.numspans; i++) {
      rebar.push(this.slabRebar(output.Asr[i * span_pos + 0]));
      rebar.push(this.beamRebar(output.Asr[i * span_pos + 1] * input.b_f / 1000));
      rebar.push(this.slabRebar(output.Asr[i * span_pos + 2]));
    }

    return rebar;
  }

/// *************** REBAR TONNAGES **********************

  private rebarEstimate(input: CalcInput, output: CalcOutput) {

    //Volume of concrete / m2
    output.volcm2 = output.Acbeam / 1E6 * 1000 / input.b_f;

    //Main rebar estimate
    let massRebar = 0;  //Total volume of rebar across all spans
    let dens = 7850; //kg/m3 steel density
    for (let i = 0; i < input.numspans; i++) {
      massRebar = massRebar + (output.Asr[i + 0] / 3 + output.Asr[i + 1] + output.Asr[i + 2] / 3) / 1E6 * input.spanlength * dens; //Assume tension steel covers 1/2 of length
    }

    //Account for additional detailing lengths, lap lengths, bars, stools etc.
    let detailingfactor = 1.3;
    massRebar = detailingfactor * massRebar;

    //Main rebar: kg / m3 of concrete
    output.mainrbm3 = massRebar / input.numspans / input.spanlength / (output.Acbeam / 1E6);

    //Main rebar: kg / m2 plan
    output.mainrbm2 = massRebar / input.numspans / input.spanlength / (input.b_f / 1E3)

  }

/// *************** MAIN FUNCTION *************************

  public calculate(input: CalcInput): CalcOutput {

    let output = new CalcOutput();

    //Setup comments
    output.messages.push({comment: 'Note: Mesh ref 156 with 25mm cover recommended as top cracking steel. Tension steel can be reduced if such mesh used.'});

    //Default comments
    output.messages.push({comment: 'Note: Forces determined based on simplified tabulated guidelines in SANS 10100-1.'});
    if (input.h_v > 100) {
      output.messages.push({comment: 'Note: Based on flange thickness verify contractors have experience to safely support slabs during construction.'});
    }

    //Setup loads
    this.getLoads(input, output);

    //Propping spacing
    output.propspace = this.deckProp(input.vpsection, input.beamsection, input.h); //Find propping spacing

    //Setup geometry properties for bending calculations
    output.d_eff = this.calcd_eff(input.h, input.cover, input.numspans); //Set effective depths throughout beams
    output.b_comp = this.b_effcomp(input.b_f, input.b_b, input.numspans); //Set compressive width for bending calculations
    output.b_wt = this.b_effcomp(input.b_b, input.b_f, input.numspans); //Set tension width for bending calculations. Inverse of previous line.

    //Calculate moments for required calculations
    output.Mu = this.calcMoments(input.numspans, input.spanlength, output.w_u, output.messages); //ULS
    output.Mf = this.calcMoments(input.numspans, input.spanlength, output.w_f, output.messages); //Fire

    //Calculate shear ULS forces
    output.Vu = this.calcShears(input.numspans, input.spanlength, output.w_u, output.messages);
    output.Vu_atd = this.calcShearsAtD(input.numspans, output.Vu, output.w_u, output.d_eff, input.suppwidth);
    output.Asutotal = this.AsULSall(input, output);

    //SLS calculations
    output.spandepthr = this.defaultSpanDepth(input.numspans, output.messages); //Get span-to-depth ratios for design
    output.Asstotal = this.calcAs_SLSall(input, output); //Get required area of SLS steel

    //Fire resistance rebar
    output.Asftotal = this.AsfireAll(input, output);

    //Minimum steel required
    output.Asmintotal = this.As_min(input, output);

    //Check thickness of flange for fire resistance
    this.h_ffiremin(input.fireresist, input.h_f, output.messages);

    //Determine required steel area based of maximum of different limit states
    this.As_final(input, output);

    //Check shear resistance
    output.Vcheck = this.checkShear_SANS(input, output);

    //Determine rebar area based on deducting the area of VP decking in tension
    output.Asr = this.calcAsr(input, output);
    output.rebarSpec = this.selectRebar(input, output);

    //Determine rebar tonnages estimates
    this.rebarEstimate(input, output);

    /* console.log('Total steel reqd.: ', output.Astotal);
     console.log('Rebar required: ', output.Asr);
     console.log('Possible rebar: ', output.rebarSpec);
     console.log('Rebar governed by: ', output.AsGovFactor);
     console.log('Comments: ', output.messages);
     console.log('Rebar - kg/m2 floor: ', Math.round(output.mainrbm2 * 10) / 10);
     console.log('Rebar - kg/m3 conc.: ', Math.round(output.mainrbm3 * 10) / 10);
     */
    console.log(input);
    console.log(output);

    return output;
  }

}
