import {IbaseModel} from "./ibase.model";

export class Occupancy implements IbaseModel {

  code: string;             // Number of occupancy
  description: string;      // Description of occupancy
  q_occupancy: number;      // Imposed load from occupancy [kPa]

  constructor(code: string, description: string, q_occupancy: number) {
    this.code = code;
    this.description = description;
    this.q_occupancy = q_occupancy;
  }

  fromOther(other: any) {
    return this;
  }

}
