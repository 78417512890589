import {Injectable} from "@angular/core";
import {FormGroup} from "@angular/forms";

@Injectable({
  providedIn: 'root'
})
export class FormService {

  markFieldsDirty(form: FormGroup) {
    for (let ctrl in form.controls) {
      form.controls[ctrl].markAsTouched();
    }

  }

}
