import {Component, OnInit} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {BaseComponent} from "../base.component";

@Component({
  selector: 'projects',
  template: `
    ProjectsListComponent
  `
})

export class ProjectsListComponent extends BaseComponent implements OnInit {

  form: FormGroup;

  ngOnInit() {
  }


}
