import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";

//https://stackoverflow.com/questions/35266798/100-height-in-ion-slide-box-not-working
//https://github.com/ionic-team/ionic-v3/issues/661

@Component({
  selector: 'input1',
  template: `
    <div *ngIf="!loading" class="input">
      <ng-container *ngFor="let element of ips.getElementsTab1()">
        <custom-input-view [model]="element"></custom-input-view>
      </ng-container>
    </div>
  `
})

export class Input1Component extends BaseComponent implements OnInit {

  ngOnInit() {
    this.loading = false;
  }

}
