//https://www.freakyjolly.com/ionic-native-http-plugin-tutorial-with-example/
//https://forum.ionicframework.com/t/ionic-native-http-instead-of-angulars-http/101326/5
//https://ionicframework.com/docs/v3/native/http/

import {Observable} from "rxjs/internal/Observable";
import {ApiAngularService} from "./api.angular.service";
import {ApiNativeService} from "./api.native.service";
import {PlatformService} from "../services/platform.service";
import {ApiConfig} from "./api.config";

export class ApiBase extends ApiConfig {

  protected get(url: string, payload: any = {}): Observable<any> {
    if (this.ps.isRunningNative()) {
      return this.ns.get(url, payload);
    } else {
      return this.as.get(url, payload);
    }
  }

  protected post(url: string, payload: any = {}): Observable<any> {
    if (this.ps.isRunningNative()) {
      return this.ns.post(url, payload);
    } else {
      return this.as.post(url, payload);
    }
  }

  constructor(public as: ApiAngularService, public ns: ApiNativeService, public ps: PlatformService) {
    super();
  }

}
