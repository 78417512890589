import {Injectable} from "@angular/core";
import {AlertController} from "@ionic/angular";
import {Observable} from "rxjs";

//https://www.freakyjolly.com/ionic-alert-this-alertcontroller-create/
//https://ionicframework.com/docs/api/alert

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  basicAlert(header, subHeader?, message?) {
    this.alertCtrl.create({
      header: header,
      subHeader: subHeader,
      message: message,
      buttons: ['OK']
    }).then(response => {
      response.present();
    });
  }

  alertDeleteAccount() {
    return new Observable(observer => {
      this.alertCtrl.create({
        header: 'Delete Account',
        message: 'Your account, including any saved data will be deleted. This operation cannot be undone. You can create a new account after this action.',
        buttons: [{
          text: 'Delete',
          handler: () => {
            observer.next(true);
          }
        },
          {
            text: 'Cancel',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              observer.next(false);
            }
          }
        ]
      }).then(alert => {
        alert.present();
      });

    });
  }

  constructor(public alertCtrl: AlertController) {
  }

}
