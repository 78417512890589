import {Component, Injectable, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../base.component";
import {OutputElement} from "../../model/output.element.model";

@Component({
  selector: 'custom-output',
  template: `
    <ion-item>
      <ion-label class="label" [title]="_model.code" [innerHTML]="_model.label"></ion-label>
      <ion-label class="value">{{_model.value}}</ion-label>
    </ion-item>
  `
})

@Injectable()
export class CustomOutputComponent extends BaseComponent implements OnInit {

  _model: OutputElement;

  set model(model: OutputElement) {
    this._model = model;
  }

  @Input() get model(): OutputElement {
    return this._model;
  }

  ngOnInit(): void {
  }

}
