import {ChangeDetectorRef, HostListener, Injectable, Injector, Renderer2} from "@angular/core";
import {FormBuilder} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {AlertController, MenuController, ModalController, Platform} from "@ionic/angular";
import {ApiService} from "../api/api.service";
import {AuthService} from "../auth/auth.service.service";
import {LoadingService} from "../services/loading.service";
import {AlertService} from "../services/alert.service";
import {PlatformService} from "../services/platform.service";
import {FormService} from "../services/form.service";
import {AppConfig} from "../app.config";
import {ParamsService} from "../services/params.service";
import {Subject} from 'rxjs';

@Injectable()
export class BaseComponent {

  config = AppConfig;
  loading: boolean = true;
  working: boolean = false;
  isMobile: boolean = false;

  onWindowSizeChange: Subject<any> = new Subject();

  @HostListener('window:resize', ['$event'])
  onWindowResize(event, forceChange: boolean) {
    let height = window.innerHeight;
    let width = window.innerWidth;
    let isMobile = (width <= 900);
    if (this.isMobile != isMobile || forceChange) {
      this.isMobile = isMobile;
      this.onWindowSizeChange.next({isMobile: isMobile, width: width});
    }
  }

  detectChanges() {
    setTimeout(() => {
      if (!this.ref['destroyed']) {
        this.ref.detectChanges();
      }
    }, 100);
  }

  constructor(public api: ApiService,
              public auth: AuthService,
              public injector: Injector,
              public fb: FormBuilder,
              public router: Router,
              public route: ActivatedRoute,
              public renderer: Renderer2,
              public ref: ChangeDetectorRef,
              public menu: MenuController,
              public platform: Platform,
              public ls: LoadingService,
              public as: AlertService,
              public ps: PlatformService,
              public fs: FormService,
              public ips: ParamsService,
              public alertController: AlertController,
              public modalController: ModalController
  ) {
  }
}
