import {Component} from "@angular/core";
import {BaseComponent} from "../base.component";
import {ApiResult} from "../../api/api.result.model";

@Component({
  template: `

    <ion-content class="ion-padding about">
      <div class="page-heading">
        About This Application
      </div>

      <ol>

        <li>Introduction</li>
        Welcome to our civil engineering application, where precision meets efficiency in designing the structural
        framework of your building projects. At our core, we prioritize accuracy, convenience, and cost-effectiveness to
        empower engineers, architects, and builders with the tools they need to streamline the beam selection process.

        <li>Purpose</li>
        To simplify the initial complexities of structural design by offering a user-friendly and mobile platform.
        With our application, you can effortlessly insert the relevant dimensions from your building plans, allowing us
        to analyze and calculate the optimal load span and quantity for your beams.

      </ol>

    </ion-content>

  `
})
export class AboutAboutComponent extends BaseComponent {

  testLoggedIn() {
    this.api.apiGet('testing', {}).subscribe((result) => {
      let apiResult = new ApiResult(result);
      if (apiResult.valid) {
        alert('logged in, result: ' + apiResult.responseObject);
      } else {
        alert('not logged in');
      }
    }, (error) => {
      if (error.status = 401) {
        alert('401 not logged in');
      } else {
        alert('error: ' + JSON.stringify(error));
      }
    });
  }

}
