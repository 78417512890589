export class CalcInput {

  //Concrete strength (MPa)
  fcu: number;

  //Yield strength of the rebar
  fyr: number;

  //fy decking
  fyd: number;

  //Imposed load ULS factor
  ilulsf: number;

  //Permanent load ULS factor
  plulsf: number;

  //Imposed load SLS factor
  ilslsf: number;

  //Permanent load SLS factor
  plslsf: number;

  //in mm
  cover: number;

  //14=Normal, 12=Light-weight
  concretetype: number;

  //Concrete density (kN/m3)
  concretedens: number;

  //in minutes: 0,30,60,90,120
  fireresist: number

  //Number of spans: 1,2,3,4
  numspans: number;

  //Support width (mm)
  suppwidth: number;

  //Span length (m)
  spanlength: number;

  //VP Section / Void number for eg 1,2 etc, see VPSectionModel
  vpsection: number;

  //Beam section number for eg 1,2 etc, see BeamSectionModel
  beamsection: any;

  //Exclude soffit 1=Yes, 0=No
  includesoffit: number;

  //Area of steel of beam (mm2/beam)
  Asb: number;

  //Area of steel of the void profile (mm2/beam)
  AsVoid: number;

  //Area of steel of ceiling (mm2/beam)
  AsCeiling: number;

  //Area of tension steel of VP system considered for design of sections
  AstVP: number;

  //Occupancy dropdown
  occupancy: number;

  //Imposed load for occupancy (kPa)
  q_occupancy: number;

  //Additional imposed load (kPa)
  q_additional: number;

  //Additional permanent load (kPa) - Optional
  g_additional: number;

  //Input parameters for the deck to keep consistent notation

  //Width of items
  b_b: number;      //Breadth of beam at base [mm]
  b_void: number;   //Breath of void - i.e. width outside of b_b [mm]
  b_bdi: number;    //Breadth of beam at top on the inside (i.e narrow section at the start of the Void section) [mm]
  b_bdo: number;    //Breadth of beam at on the outside (i.e. wide section at start of the Void section) [mm]
  b_bbf: number;    //Not in use, Breath of web at the top of the Void section (i.e. widest part) [mm]
  b_delta: number;  //Change in breadth of void between the top of the beam and the flange [mm]
  b_f: number;      //Breadth of flange [mm]

  //Height of items
  h_b: number;      //Height of beam section - typically 50mm [mm]
  h_VP: number;     //Height of VP void section [mm]
  h_v: number;      //Total height of void - VP and beam [mm]
  h_f: number;      //Height of flange [mm]
  h: number;        //Total height

}
