import {Message} from "./message.model";

export class CalcOutput {

  //VP Deck load (kPa)
  g_deck: number;

  //Permanent load (kPa)
  g_total: number;

  //Span/depth ratio
  spandepthr = new Array<number>();

  //ULS load (kPa)
  w_u: number;

  //SLS load (kPa)
  w_s: number;

  //FLS load (kPa)
  w_f: number;

  //Volume of concrete / beam [mm2/beam]
  Acbeam: number;

  //Total area of deck steel - ceiling, void and beam
  Asdtot: number;   //Total deck area

  //Volume of concrete / m2
  volcm2: number;

  //Main rebar / m3 - RANGE - 70-90 kg/m3
  mainrbm3: number;

  //Main rebar / m2 plan
  mainrbm2: number;

  //Propping spacing
  propspace: number;

  //Effective depth (d) (mm)
  d_eff = new Array<number>();

  //Effective breadth in compression for bending calculations (mm)
  b_comp = new Array<number>();

  //Effective breadth in tenson, i.e. web, for bending calculations (mm)
  b_wt = new Array<number>();

  //Ultimate Moments in a multi-span beam
  Mu = new Array<number>();

  //Fire limit state (ACC) moments in multi-span beam
  Mf = new Array<number>();

  //Ultimate shear forces at each end of a multi-span beam
  Vu = new Array<number>();

  //Shear forces at 'd' distance from the face of the supports
  Vu_atd = new Array<number>();

  //Total area of steel needed for (mm2):
  Asutotal = new Array<number>();     //ULS resistance
  Asstotal = new Array<number>();     //SLS spans
  Asftotal = new Array<number>();     //Fire resistance
  Asmintotal = new Array<number>();   //Minimum steel
  Astotal = new Array<number>();      //Total

  //Area of rebar required of steel, excluding contribution of deck
  Asr = new Array<number>();

  //Possible rebar option specification
  rebarSpec = new Array<string>();

  //Governing factor for rebar sizing, i.e. which limit state governs Atotal
  AsGovFactor = new Array<string>();

  //Shear stress capacity [MPa].
  Vcheck = new Array<string>();

  //Comment string. Errors added to it as the analysis progresses.
  messages = new Array<Message>(); //messages = new Array<Message>;
  //err_string = new Array<string>;

}
