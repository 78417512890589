import {Component, Injectable, Input} from '@angular/core';
import {BaseComponent} from "../base.component";

@Component({
  selector: 'menu-item',
  template: `

    <ion-menu-toggle auto-hide="false">
      <ion-item (click)="click()" routerDirection="root" lines="none" detail="false">
        <ion-icon slot="start" [ios]="icon + '-outline'" [md]="icon + '-sharp'"></ion-icon>
        <ion-label>{{label}}</ion-label>
      </ion-item>
    </ion-menu-toggle>

  `
})
@Injectable()
export class MenuItemComponent extends BaseComponent {

  @Input() label;
  @Input() icon;
  @Input() routeLink;
  @Input() onClick: Function;

  click() {
    if (this.onClick) {
      this.onClick();
    } else {
      this.router.navigate(this.routeLink);
    }
  }

}
