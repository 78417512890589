import {Component, Injectable, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../base.component";
import {Positions} from "../../model/positions.model";

@Component({
  selector: 'area-steel-table',
  template: `
    <div class="output-table">
      <h4>Area of Steel needed </h4>
      <table>
        <thead>
        <th>Span</th>
        <th>Position</th>
        <th>Mu</th>
        <th>Vu</th>
        <th>Asteel needed (mm2/m)</th>
        <th>Rebar Options</th>
        <th>Shear check</th>
        </thead>
        <ng-container *ngFor="let item of ips.positions.getAsASteelTable() | keyvalue">

          <tr>
            <td rowspan="4">{{item.key}}</td>
          </tr>

          <ng-container *ngFor="let pos of item.value">
            <tr>
              <td>
                {{pos.ems}}
              </td>
              <td>
                {{pos.selectedMoment | number : '1.1-1'}}
              </td>
              <td>
                {{pos.selectedShear | number : '1.1-1'}}
              </td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </ng-container>

        </ng-container>
      </table>
    </div>
  `
})

@Injectable()
export class AreaSteelTableComponent extends BaseComponent implements OnInit {

  @Input() data: Positions;

  ngOnInit(): void {
  }

}
