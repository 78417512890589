import {Injectable} from "@angular/core";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {InputElement} from "../model/input.element.model";
import {OutputElement} from "../model/output.element.model";
import {VPSection} from "../model/vp.section.model";
import {BeamSection} from "../model/beam.section.model";
import {Occupancy} from "../model/occupancy.model";
import {Positions} from "../model/positions.model";
import {CalcInput} from "../external/calc.input.model";
import {CalcOutput} from "../external/calc.output.model";
import {CalculationService} from "../external/calculation.service";
import {SelectOption} from "../model/select.option.model";
import { Subject } from "rxjs";

@Injectable()
export class ParamsService {

  onInputChanged: Subject<InputElement> = new Subject();

  output: CalcOutput = new CalcOutput();

  input = new CalcInput();

  calc: CalculationService = new CalculationService();

  //Concrete strength (MPa)
  fcu = new InputElement(
    'fcu',
    'f<sub>cu</sub> - Default (MPa)',
    'number',
    20,
    50,
    1,
    30,
    [],
    'f<sub>cu</sub> - Default (MPa)'
  );

  //yield strength of the rebar
  fyr = new InputElement(
    'fyr',
    'f<sub>y</sub> rebar',
    'number',
    250,
    550,
    1,
    450,
    [],
    'f<sub>y</sub> rebar'
  );

  fyd = new InputElement(
    'fyd',
    'f<sub>y</sub> decking',
    'number',
    550,
    550,
    1,
    550,
    [],
    'f<sub>y</sub> decking'
  );

  //mostly one decimal place but we should allow for 2 decimal places
  ilulsf = new InputElement(
    'ilulsf',
    'Imposed load ULS factor',
    'number',
    0,
    3,
    0.1,
    1.6,
    [],
    'Imposed load ULS factor'
  );

  //Permanent Load Ultimate Limit State (ULS) factor
  plulsf = new InputElement(
    'plulsf',
    'Permanent load ULS factor',
    'number',
    0,
    3,
    0.1,
    1.2,
    [],
    'Permanent load ULS factor'
  );

  //Imposed Load Serviceability (SLS) factor
  ilslsf = new InputElement(
    'ilslsf',
    'Imposed load SLS factor',
    'number',
    0,
    3,
    0.1,
    1,
    [],
    'Imposed load SLS factor'
  );

  //Permanent Load Serviceability Limit State (SLS) factor
  plslsf = new InputElement(
    'plslsf',
    'Permanent load SLS factor',
    'number',
    0,
    3,
    0.1,
    1.1,
    [],
    'Permanent load SLS factor'
  );

  // in mm
  cover = new InputElement(
    'cover',
    'Cover (mm)',
    'select',
    null,
    null,
    null,
    25,
    [
      {label: '20 mm', value: 20},
      {label: '25 mm', value: 25},
      {label: '30 mm', value: 30},
      {label: '35 mm', value: 35},
      {label: '40 mm', value: 40}
    ],
    'Cover'
  );
  concretetype = new InputElement(
    'concretetype',
    'Concrete type',
    'select',
    null,
    null,
    null,
    24,
    [
      {label: 'Light-weight', value: 12},
      {label: 'Normal', value: 24}
    ],
    'A Concrete type of Normal represents a density of 24 kN/m<sup>3</sup> and Light-weight a density of 12 kN/m<sup>3</sup>'
  ).setShowSelectedOption(true);

  //To be set when concretetype is set, but can be override with custom value (kN/m3)
  concretedens = new InputElement(
    'concretedens',
    'Concrete density',
    'number',
    0,
    100,
    1,
    24,
    [],
    'Concrete density (kN/m3)'
  );

  modfact = new InputElement(
    'modfact',
    'Mod factor',
    'number',
    1,
    5,
    0.1,
    1.2,
    [],
    'Mod factor'
  );

  fireresist = new InputElement(
    'fireresist',
    'Fire resistance (min)',
    'select',
    null,
    null,
    null,
    0,
    [
      {label: '0 min', value: 0},
      {label: '30 min', value: 30},
      {label: '60 min', value: 60},
      {label: '90 min', value: 90},
      {label: '120 min', value: 120}
    ],
    'Fire resistance (min)'
  );

  numspans = new InputElement(
    'numspans',
    'Number of spans',
    'select',
    null,
    null,
    null,
    3,
    [
      {label: 1, value: 1},
      {label: 2, value: 2},
      {label: 3, value: 3},
      {label: '4 or more', value: 4}
    ],
    'Number of spans'
  );

  suppwidth = new InputElement(
    'suppwidth',
    'Support width (mm)',
    'number',
    50,
    400,
    1,
    230,
    [],
    'Support width (mm)'
  );

  spanlength = new InputElement(
    'spanlength',
    'Span length (m)',
    'number',
    1,
    10,
    0.1,
    7.5,
    [],
    'Span length (m)'
  );

  //one span depth ratio per span, for version 1 this will be internal to calculation.service.ts
  _spandepthr = new InputElement(
    'spandepthr',
    'Span/depth ratio',
    'number',
    null,
    null,
    0.1,
    16,
    [],
    'Span/depth ratio'
  );

  totslabdepth = new InputElement(
    'totslabdepth',
    'Total slab depth (mm)',
    'number',
    100,
    500,
    1,
    300,
    [],
    'Total slab depth (mm)'
  );

  vpsection = new InputElement(
    'vpsection',
    'VP Section / Void depth (mm)',
    'select',
    null,
    null,
    null,
    6,
    [],
    'VP Section / Void depth (mm)'
  ).setShowSelectedOption(true);

  beamsection = new InputElement(
    'websection',
    'Beam section',
    'select',
    null,
    null,
    null,
    2,
    [],
    'Beam section'
  ).setShowSelectedOption(true);

  webwidth = new InputElement(
    'webwidth',
    'Web width (mm)',
    'number',
    null,
    null,
    1,
    2,
    [],
    'Web width (mm)',
    true
  );

  flangewidth = new InputElement(
    'flangewidth',
    'T-beam total flange width (mm)',
    'number',
    null,
    null,
    1,
    null,
    [],
    'T-beam total flange width (mm)',
    true
  );

  voiddepth = new InputElement(
    'voiddepth',
    'Void depth (mm)',
    'number',
    null,
    null,
    1,
    50,
    [],
    'Void depth (mm)',
    true
  );

  _includesoffit = new InputElement(
    'includesoffit',
    'Exclude soffit',
    'select',
    null,
    null,
    null,
    0,
    [
      {label: 'Yes', value: 1},
      {label: 'No', value: 0}
    ],
    'Exclude soffit'
  ).setShowSelectedOption(true);

  areasteelbeam = new InputElement(
    'areasteelbeam',
    'Area of steel of beam (mm<sup>2</sup>)',
    'number',
    null,
    null,
    1,
    null,
    [],
    'Area of steel of beam (mm<sup>2</sup>)',
    true
  );

  occupancy = new InputElement(
    'occupancy',
    'Occupancy',
    'select',
    null,
    null,
    null,
    'B1',
    [],
    'Occupancy'
  );
  vpdeckload = new InputElement(
    'vpdeckload',
    'VP Deck load (kPa)',
    'number',
    null,
    null,
    1,
    null,
    [],
    'VP Deck load (kPa)',
    true
  );

  imploadoccupancy = new InputElement(
    'imploadoccupancy',
    'Imposed load for occupancy (kPa)',
    'number',
    1,
    10,
    1,
    1,
    [],
    'Imposed load for occupancy (kPa)'
  );

  addimposedload = new InputElement(
    'addimposedload',
    'Additional imposed load (kPa)',
    'number',
    0,
    10,
    0.5,
    0,
    [],
    'Additional imposed load (kPa)'
  );

  permload = new InputElement(
    'permload',
    'Permanent load (kPa)',
    'number',
    0,
    50,
    1,
    0,
    [],
    'Permanent load (kPa)'
  );

  addpermload = new InputElement(
    'addpermload',
    'Additional permanent load (kPa)',
    'number',
    0,
    10,
    0.1,
    0.9,
    [],
    'Additional permanent load (kPa)'
  );

  //partitions = new InputElement('partitions', 'Partitions', 'select', null, null, null, '0.5', [{label: '1 kPa', value: 1}]);

  areavcdeck = new OutputElement(
    'areavcdeck',
    'Area of Voidcon deck as rebar (mm<sup>2</sup>/m)',
    'Area of Voidcon deck as rebar (mm<sup>2</sup>/m)',
    null
  );

  deflpf = new OutputElement(
    'deflpf',
    'Deflection - Pass/Fail',
    'Deflection - Pass/Fail',
    'Pass'
  );

  rebarmp = new OutputElement(
    'rebarmp',
    'Amount of rebar - Multiple positions',
    'Amount of rebar - Multiple positions'
  );

  frebarmp = new OutputElement(
    'frebarmp',
    'Amount of fire rebar - Multiple positions',
    'Amount of fire rebar - Multiple positions'
  );

  volcbeam = new OutputElement(
    'volcbeam',
    'Volume of concrete / beam (m<sup>3</sup>)',
    'Volume of concrete / beam (m<sup>3</sup>)'
  );

  volcm2 = new OutputElement(
    'volcm2',
    'Volume of concrete (m<sup>3</sup>/m<sup>2</sup>)',
    'Volume of concrete (m<sup>3</sup>/m<sup>2</sup>)s'
  );

  mainrbm3 = new OutputElement(
    'mainrbm3',
    'Main rebar (kg/m<sup>3</sup>)',
    'Main rebar (kg/m<sup>3</sup>)'
  );

  mainrbm2 = new OutputElement(
    'mainrbm2',
    'Main rebar plan (kg/m<sup>2</sup>)',
    'Main rebar plan (kg/m<sup>2</sup>)'
  );

  propspace = new OutputElement(
    'propspace',
    'Propping spacing',
    'Propping spacing'
  );

  ulsload = new OutputElement(
    'ulsload',
    'ULS load (kPa)',
    'ULS load (kPa)'
  );

  slsload = new OutputElement(
    'slsload',
    'SLS load (kPa)',
    'SLS load (kPa)'
  );


  getElementsTab1(): InputElement[] {
    return [this.numspans, this.spanlength, this.suppwidth, this.cover, this.fireresist];
  }

  getElementsTab2(): InputElement[] {
    if (this.vpsection.options.length == 0) {
      this.vpSections.forEach(section => {
        this.vpsection.options.push(new SelectOption(section.vProfile, section.no));
      });
    }
    this.vpsection.setSelectedOption();

    if (this.beamsection.options.length == 0) {
      this.webSections.forEach(section => {
        this.beamsection.options.push({label: section.VPBeam, value: section.no});
      });
    }
    this.beamsection.setSelectedOption()

    return [this.totslabdepth, this.vpsection, this.beamsection, this.flangewidth, this.webwidth, this.voiddepth, this.areasteelbeam];
  }

  getElementsTab3(): InputElement[] {
    if (this.occupancy.options.length == 0) {
      this.occupancies.forEach(item => {
        this.occupancy.options.push(new SelectOption(item.code + ' ' + item.description, item.code));
      });
    }
    return [this.vpdeckload, this.occupancy, this.imploadoccupancy, this.addimposedload, this.permload, this.addpermload];
  }

  getElementsDefaultsTab(): InputElement[] {
    return [this.fcu, this.fyr, this.fyd, this.ilulsf, this.plulsf, this.ilslsf, this.plslsf, this.concretetype, this.concretedens, this.modfact];
  }

  getOutputTab1(): OutputElement[] {
    return [this.areavcdeck, this.propspace, this.volcbeam, this.volcm2, this.mainrbm3, this.mainrbm2, this.ulsload, this.slsload];
  }

  addFormFields(form: FormGroup, inputElements: InputElement[]) {
    inputElements.forEach(element => {
      form.addControl(element.code, new FormControl(element.value, [Validators.required, Validators.min(element.min), Validators.max(element.max)]));
    });
  }

  vpSections: Array<VPSection> = [
    new VPSection(1, 'VP250', 'V200', 'B300 & B300', 600, 250, 84, 790.3, 707.8),
    new VPSection(2, 'VP200', 'V150', 'B300 & B300', 600, 200, 66, 718.6, 707.8),
    new VPSection(3, 'VP120', 'V70', 'B225 & B225', 450, 120, 30, 486.4, 587.8),
    new VPSection(4, 'VP75', 'V25', 'B300', 300, 75, 10, 302, 353.9),
    new VPSection(5, 'VP50 - 300 voids', 'V0_300', 'B300', 300, 50, 0, 266.1, 353.9),
    new VPSection(6, 'VP50 - 225 voids', 'V0_225', 'B225', 225, 50, 0, 232.5, 293.9),
    new VPSection(7, 'VP50 - No voids', 'NA', '-', 0, 50, 0, 0, 0)
  ];

  webSections: Array<BeamSection> = [
    new BeamSection(1, 'VP Beam 150', 'B150', 50, 150, 130, 170, 233.9),
    new BeamSection(2, 'VP Beam 225', 'B225', 50, 225, 205, 245, 293.9),
    new BeamSection(3, 'VP Beam 300', 'B300', 50, 300, 280, 320, 353.9),
    new BeamSection(4, 'VP Beam 375', 'B375', 50, 375, 355, 395, 413.9),
    new BeamSection(5, 'VP Beam 450', 'B450', 50, 450, 430, 470, 473.9)
  ]

  occupancies: Array<Occupancy> = [
    new Occupancy('A1', 'All rooms in a dwelling unit and a dwelling house, including corridors and lobbies', 1.5),
    new Occupancy('A2', 'Bedrooms, wards, dormitories, private bathrooms and toilets in hospitals, hotels, hostels and other institutional residential occupancies ', 2),
    new Occupancy('A3', 'Stairs and escape routes in residential occupancies for example, serving hospitals, hotels, hostels and other institutional residential occupancies', 3),
    new Occupancy('A4', 'Balconies accessible to domestic and residential occupancy areas', 4),
    new Occupancy('B1', 'Office areas for general use', 2.5),
    new Occupancy('B2', 'Public libraries, excluding stack areas ', 3),
    new Occupancy('B3', 'Kitchens, communal bathrooms and toilets in educational buildings, hotels, office buildings and other institutional occupancies', 3),
    new Occupancy('B4', 'Light laboratories, operating theatres, X-ray rooms', 3),
    new Occupancy('B5', 'Filing and office storage areas and stack areas in libraries and archives', 0), //user defined?
    new Occupancy('C1', 'Areas with movables such as furniture and tables for example, class rooms, areas in schools, cafés, restaurants, dining halls, reading rooms, reception areas, banking halls', 3),
    new Occupancy('C2', 'Areas with fixed seats, for example, areas in churches, theatres or cinemas, conference rooms, lecture halls, assembly halls, waiting rooms, railway waiting rooms; grandstands with fixed individual seating', 4),
    new Occupancy('C3', 'Areas without obstacles for moving people, all without fixed individual seating for example, assembly halls and areas, sport complexes, grandstands, areas in museums or exhibition rooms, and access areas in public and administration buildings, hotels, hospitals, airports, railway station forecourts and terminals; stairs, corridors, landings; cantilever balconies accessible to the public', 5),
    new Occupancy('C4', 'Areas with possible physical activities, for example, dance halls, gymnastic rooms, stages', 5),
    new Occupancy('C5', 'Areas susceptible to large crowds, for example, in buildings for public events like concert halls, exhibition halls, sports halls including stands, terraces, access areas, escape routes and railway platforms', 5),
    new Occupancy('D', 'Areas in general retail shops and department stores', 5)
  ]

  /**
   * E – End (edge) (Left)
   * M – Midspan of beam
   * S – At support (Right)
   * Depending on the number of spans the position of the end will change)
   */
  static ems = {E: 'E', M: 'M', S: 'S'}

  positions: Positions = new Positions();

  lookupVpSection(sectionNo): VPSection {
    let section = this.vpSections.filter(section => section.no == sectionNo)[0];
    return section;
  }

  lookupWebSection(sectionNo): BeamSection {
    let section = this.webSections.filter(section => section.no == sectionNo)[0];
    return section;
  }

  lookupOccupancy(occCode): Occupancy {
    let occupancy = this.occupancies.filter(occ => occ.code == occCode)[0];
    return occupancy;
  }

  public selectedVpSection: VPSection;

  public selectedWebSection: BeamSection;

  public selectedOccupancy: Occupancy;

  public asCalcInput(): CalcInput {
    this.input = new CalcInput();
    this.input.fcu = this.fcu.valueAsNumber;
    this.input.fyr = this.fyr.valueAsNumber;
    this.input.fyd = this.fyd.valueAsNumber;
    this.input.ilulsf = this.ilulsf.valueAsNumber;
    this.input.plulsf = this.plulsf.valueAsNumber;
    this.input.ilslsf = this.ilslsf.valueAsNumber;
    this.input.plslsf = this.plslsf.valueAsNumber;
    this.input.cover = this.cover.valueAsNumber;
    this.input.concretetype = this.concretetype.valueAsNumber;
    this.input.concretedens = this.concretedens.valueAsNumber * 0.000001;
    this.input.fireresist = this.fireresist.valueAsNumber;
    this.input.numspans = this.numspans.valueAsNumber;
    this.input.spanlength = this.spanlength.valueAsNumber;
    this.input.suppwidth = this.suppwidth.valueAsNumber;
    this.input.vpsection = this.vpsection.valueAsNumber;
    this.input.beamsection = this.beamsection.valueAsNumber;

    //input.includesoffit = this.includesoffit.valueAsNumber;    //to be used later
    this.input.occupancy = this.selectedOccupancy.q_occupancy;

    this.input.g_additional = this.addpermload.valueAsNumber;  //Additional permanent load (kPa) - Optional
    this.input.q_additional = this.addimposedload.valueAsNumber;  //Additional imposed load (kPa)
    this.input.q_occupancy = this.selectedOccupancy.q_occupancy;

    //breadth/horizontal
    this.input.b_b = this.selectedWebSection.b_b;
    this.input.b_void = this.selectedVpSection.b_void;
    this.input.b_bdi = this.selectedWebSection.b_bdi;
    this.input.b_bdo = this.selectedWebSection.b_bdo;
    this.input.b_delta = this.selectedVpSection.b_delta;
    this.input.b_f = this.input.b_b + this.input.b_void; //Breadth of flange [mm]
    this.input.b_bbf = 0;  //Not in use, Breath of web at the top of the Void section (i.e. widest part) [mm]

    //height/vertical
    this.input.h = this.totslabdepth.valueAsNumber;      //Total height
    this.input.h_b = this.selectedWebSection.h_b;
    this.input.h_v = this.selectedVpSection.h_v;
    this.input.h_f = this.input.h - this.input.h_v;
    this.input.h_VP = this.input.h_f - this.input.h_b;   //Height of VP void section [mm]

    this.input.Asb = this.selectedWebSection.Asb;
    this.input.AsVoid = this.selectedVpSection.AsVoid;
    this.input.AsCeiling = this.selectedVpSection.AsCeiling
    this.input.AstVP = this.input.Asb;  //Area of tension steel of VP system considered for design of sections

    console.log("input: ", this.input);

    return this.input;
  }

  setOutput() {
    this.propspace.value = this.output.propspace;
    this.volcm2.value = this.output.volcm2;
    this.volcbeam.value = this.output.Acbeam / 1000000;
    this.mainrbm3.value = this.output.mainrbm3;
    this.mainrbm2.value = this.output.mainrbm2;
    this.slsload.value = this.output.w_s;
    this.ulsload.value = this.output.w_u;

    let flangeWidth = this.selectedVpSection.b_void + this.selectedWebSection.b_b;
    this.flangewidth.setValue(flangeWidth);

    this.webwidth.setValue(this.selectedWebSection.b_b);
    this.voiddepth.setValue(this.selectedVpSection.h_v);
    this.areasteelbeam.setValue(this.selectedWebSection.Asb);
    this.vpdeckload.setValue(this.output.g_deck.toFixed(2));
    this.areavcdeck.value = this.selectedWebSection.Asb;
  }

  calculate() {
    this.selectedVpSection = this.lookupVpSection(this.vpsection.valueAsNumber);
    this.selectedWebSection = this.lookupWebSection(this.beamsection.valueAsNumber);
    this.selectedOccupancy = this.lookupOccupancy(this.occupancy.value);
    this.output = this.calc.calculate(this.asCalcInput());
    console.log("this.output", this.output);
    this.setOutput();
    this.onInputChanged.next(null);
  }

}
