import {Observable} from "rxjs";

import {HTTP} from '@ionic-native/http/ngx';
import {ApiConfig} from "./api.config";
import {Injectable} from "@angular/core";

//https://www.freakyjolly.com/ionic-native-http-plugin-tutorial-with-example/
//https://forum.ionicframework.com/t/ionic-native-http-instead-of-angulars-http/101326/5
//https://ionicframework.com/docs/v3/native/http/

/**
 * Use the native http service when running on mobile device (Android or iOS)
 */

@Injectable()
export class ApiNativeService extends ApiConfig {

  private getHeaderOptions() {
    return {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${this.getAuthToken()}`
    }
  }

  private setHttpOptions() {
    this.http.setHeader(null, 'Content-Type', 'application/json');
    this.http.setHeader(null, 'Authorization:', `Bearer ${this.getAuthToken()}`);
    this.http.setDataSerializer('json');
    this.http.setRequestTimeout(10);
    this.http.setServerTrustMode('nocheck');
  }

  post(url: string, payload: any = {}): Observable<any> {
    this.setHttpOptions();
    return new Observable(observer => {
      this.http.post(url, payload, this.getHeaderOptions())
        .then(response => {
          //alert("result " + JSON.stringify(resp.data));
          observer.next(JSON.parse(response.data));
        }).catch(response => {
        alert("error " + JSON.stringify(response));
        observer.next(response);
        // prints 403
        console.log("error", JSON.stringify(response));
        // prints Permission denied
        console.log(response.error);
      });

    })
  }

  get(url: string, payload: any = {}): Observable<any> {
    this.setHttpOptions();
    return new Observable(observer => {
      this.http.get(url, payload, this.getHeaderOptions())
        .then(response => {
          //alert("result " + JSON.stringify(resp.data));
          observer.next(JSON.parse(response.data));

        }).catch(response => {
        alert("error " + JSON.stringify(response));
        observer.next(response);
        // prints 403
        console.log("error", JSON.stringify(response));
        // prints Permission denied
        console.log(response.error);
      });

    })
  }

  constructor(public http: HTTP) {
    super();
  }

}
