import {IbaseModel} from "./ibase.model";
import {Position} from "./position.model";

/**
 * See ForceCalculation sheet
 */
export class Positions extends Map<number, Position> implements IbaseModel {

  setOne(value: Position): this {
    value.code = value.ems;
    super.set(value.index, value)
    return this;
  }

  setMany(values: Array<Position>) {
    values.forEach(value => {
      this.setOne(value);
    });
  }

  getAsASteelTable() {
    let result = new Map<number, Array<Position>>();
    this.forEach((position: Position, index: number) => {
      let key = position.spanNo;
      if (!result.has(key)) {
        result.set(key, new Array<Position>());
      }
      let arr = result.get(key);
      arr.push(position);
    });
    //console.log("result", result);
    return result;
  }

  set(key: number, value: Position): this {
    throw new Error('Not implemented');
  }

  fromOther(other: any) {
    return this;
  }

}
