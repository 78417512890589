import {Injectable} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {Platform} from "@ionic/angular";

//https://capacitorjs.com/docs/apis/network

@Injectable({
  providedIn: 'root'
})
export class PlatformService {

  isOnline() {
    return navigator.onLine;
  }

  isRunningNative() {
    return !this.isRunningOnWeb();
  }

  isRunningOnWeb(): boolean {
    return this.platform.is('desktop') || this.platform.is('mobileweb');
  }

  gotoEstimator() {
    this.router.navigate(['/estimator'])
  }

  /**
   * Switch between 2 separate views for desktop and mobile displays
   * @param result
   * @param route
   */
  checkResizeEstimatorMobileDesktop(result: any, route: ActivatedRoute) {
    //console.log("onWindowSizeChange:", result.isMobile);
    let currentRoute: string = route.snapshot.url.length > 0 ? route.snapshot.url[0].path : '';
    console.log("currentRoute:", currentRoute);
    if (currentRoute.indexOf('estimator') == -1) {
      return;
    }
    if (result.isMobile && currentRoute != 'estimator-mobile') {
      this.router.navigate(['/estimator-mobile'])
    } else if (!result.isMobile && currentRoute != 'estimator-desktop') {
      this.router.navigate(['/estimator-desktop'])
    }
  }

  constructor(public platform: Platform, public router: Router) {
    //  Network.addListener('networkStatusChange', status => {
    //    console.log('Network status changed', status);
    //   });

    const logCurrentNetworkStatus = async () => {
      //const status = await Network.getStatus();

      // console.log('Network status:', status);
    };
  }

}

