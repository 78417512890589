import {IbaseModel} from "./ibase.model";

export class BeamSection implements IbaseModel {

  no: number;
  VPBeam: string;           // Beam profile
  VPBeamShortName: string;  // Short name
  h_b: number;              // Height of beam [mm]
  b_b: number;              // Width of beam [mm]
  b_bdi: number;            // Inside width inside beam at top [mm]
  b_bdo: number;            // Outside width beam at top [mm]
  Asb: number;              // Area of beam steel [mm2]

  constructor(no: number, VPBeam: string, VPBeamShortName: string, h_b: number, b_b: number, b_bdi: number, b_bdo: number, Asb: number) {
    this.no = no;
    this.VPBeam = VPBeam;
    this.VPBeamShortName = VPBeamShortName;
    this.h_b = h_b;
    this.b_b = b_b;
    this.b_bdi = b_bdi;
    this.b_bdo = b_bdo;
    this.Asb = Asb;
  }

  fromOther(other: any) {
    return this;
  }

}
