import {environment} from "../../environments/environment";
import {AppConfig} from "../app.config";

//https://www.freakyjolly.com/ionic-native-http-plugin-tutorial-with-example/
//https://forum.ionicframework.com/t/ionic-native-http-instead-of-angulars-http/101326/5
//https://ionicframework.com/docs/v3/native/http/

export class ApiConfig {

  apiBaseUrl = environment.apiBaseUrl;
  apiUrl = this.apiBaseUrl + 'api.php';

  protected getAuthToken() {
    const loggedInUser = JSON.parse(localStorage.getItem(AppConfig.localStorageKey));
    if (loggedInUser) {
      return loggedInUser.token;
    }
    return null;
  }

}
