export class ApiResult {

  statusCode: string;

  resultCode: string;

  responseObject: any;

  valid: boolean = true;

  invalid: boolean = false;

  get hasResult(): boolean {
    if (this.responseObject) return true;
    return false;
  }

  clear() {
    this.invalid = false;
    this.valid = !this.invalid;
  }

  constructor(other?) {
    if (other != null) {
      this.statusCode = other.statusCode;
      this.resultCode = other.resultCode;
      this.responseObject = other.responseObject;
      this.invalid = (this.statusCode != 'S');
      this.valid = !this.invalid;
    }
  }

}
