import {Component, Injectable, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../base.component";
import {Message} from "../../external/message.model";

@Component({
  selector: 'calculation-messages',
  template: `
      <div class="calculation-messages">
          <h4>Calculation Messages</h4>
          <table>
              <ng-container *ngFor="let msg of messages">
                  <tr [ngClass]="{'error': msg.error, 'warning' : msg.warning,'comment' : msg.comment }">
                      <td>{{msg.error || msg.warning || msg.comment}}</td>
                  </tr>
              </ng-container>
          </table>
      </div>
  `
})

@Injectable()
export class CalculationMessagesComponent extends BaseComponent implements OnInit {

  @Input() messages: Array<Message>;

  ngOnInit(): void {
  }

}
