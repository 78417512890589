import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiService} from "../api/api.service";
import {ApiResult} from "../api/api.result.model";
import {AppConfig} from "../app.config";

//https://stackoverflow.com/questions/26475885/authorization-header-missing-in-php-post-request

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private loggedUserSubject: BehaviorSubject<any>;
  public loggedInUser: Observable<any>;

  loginUser(username: string, password: string) {
    return new Observable(observer => {
      this.api.loginPost({
        un: username,
        pw: password
      }).subscribe((result) => {
        let apiResult = new ApiResult(result);
        //alert('apiResult' + JSON.stringify(apiResult.responseObject));
        if (apiResult.valid) {
          localStorage.setItem(AppConfig.localStorageKey, JSON.stringify(apiResult.responseObject));
          this.loggedUserSubject.next(apiResult.responseObject);
          observer.next(new ApiResult({statusCode: 'S'}));
        } else {
          observer.next(new ApiResult({statusCode: 'E', responseObject: result.responseObject}));
        }
      }, (error) => {
        observer.next(new ApiResult({statusCode: 'E', responseObject: `${error.name} (${error.message})`}));
      });
    });
  }

  logoutUser() {
    this.api.apiPost('logout', {}).subscribe((result) => {
    });
    localStorage.removeItem(AppConfig.localStorageKey);
    this.loggedUserSubject.next(null);
  }

  public get isLoggedIn() {
    if (this.loggedInUserValue) return true;
    return false;
  }

  public get loggedInUserValue() {
    return this.loggedUserSubject.value;
  }

  public get email() {
    if (this.isLoggedIn) return this.loggedInUserValue['email_address'];
  }

  public get fullName() {
    if (this.isLoggedIn) return this.loggedInUserValue['firstName'] + ' ' + this.loggedInUserValue['lastName'];
  }

  public getToken() {
    if (this.isLoggedIn) {
      return this.loggedInUserValue['token'];
    }
    return null;
  }

  constructor(public api: ApiService) {
    this.loggedInUser = JSON.parse(localStorage.getItem(AppConfig.localStorageKey));
    this.loggedUserSubject = new BehaviorSubject(this.loggedInUser)
    this.loggedInUser = this.loggedUserSubject.asObservable();
  }

}
