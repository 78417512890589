import {IbaseModel} from "./ibase.model";

export class OutputElement implements IbaseModel {

  code: string;
  label: string;
  private _value: number | string;
  description: string;                // may contain html

  set value(value: number | string) {
    if (typeof value === 'number') {
      value = value.toFixed(2);
    }
    this._value = value;
  }

  get value(): number | string {
    return this._value;
  }

  constructor(code: string, label: string, description, value: number | string = null) {
    this.code = code;
    this.label = label;
    this.description = description;
    this.value = value;
  }

  fromOther(other: any) {
    return this;
  }

}
