import {Component} from "@angular/core";
import {BaseComponent} from "../base.component";

@Component({
  template: `

      <ion-content class="ion-padding about">
          <div class="page-heading">
              Privacy Policy
          </div>
          This Privacy Policy explains how Void Pro Manufacturing (PTY) Ltd collects, uses, and protects personal
          information provided by users of the <i>Voidcon Design Application</i>. By using our application, you agree
          to the terms outlined in this policy.

          <ol>

              <li>Information We Collect</li>
              <ul>
                  <li>Personal Information</li>
                  We may collect personal information such as names, email addresses,
                  and contact details when voluntarily provided by users for account creation, communication, or support
                  purposes.
                  <li>Usage Data</li>
                  We gather information about how users interact with our application, including
                  log data, IP addresses, device information, and pages visited, to improve user experience and enhance
                  application functionality.
              </ul>

              <li>How We Use Your Information</li>
              <ul>
                  <li>Service Delivery</li>
                  We use collected information to provide and maintain our application's
                  functionality, ensuring a personalized and efficient user experience.

                  <li>Communication</li>
                  We may use personal information to communicate with users, respond to
                  inquiries, provide updates, and offer support.

                  <li>Analytics</li>
                  Collected data is utilized for analytics purposes to enhance and optimize our
                  application, addressing user preferences and improving overall performance.
              </ul>

              <li>Data Security</li>
              We employ industry-standard security measures to protect against unauthorized access,
              alteration, disclosure, or destruction of personal information. However, no method of transmission over
              the internet or electronic storage is entirely secure, and we cannot guarantee absolute security.

              <li>Data Sharing</li>

              Void Pro Manufacturing does not sell, trade, or rent personal information to third parties. We may share
              information with trusted third-party service providers who assist us in operating our application,
              conducting business, or servicing users, as long as those parties agree to keep this information
              confidential.

              <li>Cookies and Tracking Technologies</li>
              Our application uses cookies and similar tracking technologies to enhance user experience,
              customize content, and gather information about usage patterns. Users can control cookie preferences
              through their browser settings.

              <li>Your Rights</li>
              Users have the right to access, correct, or delete their personal information. To exercise these
              rights or inquire about the data we hold, please email <a href="query@voidcon.com">query@voidcon.com</a>.

              <li>Changes to this Privacy Policy</li>
              Void Pro Manufacturing reserves the right to modify this Privacy Policy. Users will be notified of
              significant changes, and it is recommended to review the policy periodically.

          </ol>

          By using our application, you consent to the terms outlined in this Privacy Policy. For any privacy-related
          inquiries, please email <a href="query@voidcon.com">query@voidcon.com</a>.

      </ion-content>

  `
})
export class AboutPrivacyComponent extends BaseComponent {
}
