import {Component, Injectable, Input} from '@angular/core';
import {BaseComponent} from "../base.component";

//https://www.remotestack.io/how-to-create-sidebar-navigation-in-ionic-angular-app/
//https://ionicframework.com/docs/api/menu
//https://ionicframework.com/docs/angular/navigation
//https://www.freakyjolly.com/ionic-side-menu-navigation-tutorial-example-application/

@Component({
  selector: 'main-menu',
  templateUrl: 'main.menu.component.html'
})
@Injectable()
export class MainMenuComponent extends BaseComponent {

  @Input() title: string = 'Voidcon Design Application';

  menuItems = [
    {
      name: 'Sign In',
      link: '/signin',
      icon: 'log-in',
      hideLoggedIn: true
    },
    {
      name: 'Estimator',
      link: '/estimator',
      icon: 'calculator',
      showLoggedIn: true
    },
    {
      name: 'Profile',
      link: '/profile',
      icon: 'person-circle',
      showLoggedIn: true
    },
    {
      name: 'About',
      link: '/about/about',
      icon: 'information-circle',
      showAlways: true
    },
    {
      name: 'Help',
      link: '/help',
      icon: 'help-circle',
      showAlways: true
    },
  ]

  logout() {
    this.auth.logoutUser();
    this.router.navigate(['/intro']);
  }

  exitApp() {
    navigator['app'].exitApp();
  }

}
