import {Component, Injectable, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../base.component";
import {InputElement} from "../../model/input.element.model";
import {CustomInputModal} from "./custom.input.modal";

/**
 * Readonly input line item that can be changed by opening CustomInputModal
 */
@Component({
  selector: 'custom-input-view',
  template: `
    <ion-item class="view-item">
      <ion-label class="label" slot="start" [title]="_model.code" [innerHTML]="_model.label"></ion-label>
      <ion-label class="spacer"></ion-label>
      <ion-button color="secondary" [disabled]="_model.readonly" (click)="openEditModal()">{{ value }}</ion-button>
    </ion-item>
  `
})

@Injectable()
export class CustomInputViewComponent extends BaseComponent implements OnInit {

  _model: InputElement;
  value: number | string = '';

  set model(model: InputElement) {
    this._model = model;
    if (this._model._showSelectedOption && this._model.selectedOption != null) {
      this.value = this._model.selectedOption.label;
    } else if (this._model.defaultValue != null) {
      this.value = this._model.defaultValue;
    }
  }

  @Input() get model(): InputElement {
    return this._model;
  }

  async openEditModal() {
    const modal = await this.modalController.create({
      component: CustomInputModal,
      componentProps: {model: this._model},
      cssClass: 'edit-modal',
      backdropDismiss: true
    });
    modal.present();
    const modalData = await modal.onWillDismiss();
    if (modalData.data) {
      this._model.setSelectedOption();
      if (this._model._showSelectedOption && this._model.selectedOption != null) {
        this.value = this._model.selectedOption.label;
      } else {
        this.value = this._model.value
      }
      this._model.value = modalData.data.model.value;
      this.ips.calculate();
    }
  }

  ngOnInit(): void {
    this._model.onSetValue.subscribe((value) => {
      this.value = value;
      this.detectChanges();
    });
  }

}
