import {Component} from '@angular/core';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Platform} from '@ionic/angular';
import {AuthService} from "./auth/auth.service.service";
import {PlatformService} from "./services/platform.service";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html'
})
export class AppComponent {

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
    });
  }

  constructor(
    private platform: Platform,
    private statusBar: StatusBar,
    private auth: AuthService,
    private ps: PlatformService,
  ) {
    this.initializeApp();

    if (this.auth.isLoggedIn) {
      this.ps.gotoEstimator();
    }

  }

}
