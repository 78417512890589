import {IbaseModel} from "./ibase.model";

export class VPSection implements IbaseModel {

  no: number;
  vProfile: string;          // VP profile
  voidProfileVoid: string;   // Profile
  ceilingProfile: string;    // Ceiling profile
  h_v: number;               // Height of void [mm]
  b_void: number;            // Flange width excl. beam [mm]
  b_delta: number;           // Change in beam width over height [mm]
  AsVoid: number;            // Area of steel of Void section [mm2]
  AsCeiling: number;         // Area of steel of ceiling [mm2]

  constructor(no: number, vProfile: string, voidProfileVoid: string, ceilingProfile: string, b_void: number, h_v: number, b_delta: number, AsVoid: number, AsCeiling: number) {
    this.no = no;
    this.vProfile = vProfile;
    this.voidProfileVoid = voidProfileVoid;
    this.ceilingProfile = ceilingProfile;
    this.h_v = h_v;
    this.b_void = b_void;
    this.b_delta = b_delta;
    this.AsVoid = AsVoid;
    this.AsCeiling = AsCeiling;
  }

  fromOther(other: any) {
    return this;
  }

}
