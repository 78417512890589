import {Inject, Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {PlatformService} from "../services/platform.service";
import {ApiAngularService} from "./api.angular.service";
import {ApiNativeService} from "./api.native.service";
import {ApiBase} from "./api.base.service";

@Injectable()
@Inject(HttpClient)
export class ApiService extends ApiBase {

  apiGet(action: string, payload = {}): Observable<any> {
    return this.get(this.apiUrl, {action: action, payload: JSON.stringify(payload)});
  }

  apiPost(action: string, payload: any): Observable<any> {
    return this.post(this.apiUrl, {action: action, payload: payload});
  }

  loginPost(payload: any): Observable<any> {
    return this.post(this.apiBaseUrl + 'login.php', {payload: payload});
  }

  signupPost(payload: any): Observable<any> {
    return this.post(this.apiBaseUrl + 'signup.php', {payload: payload});
  }

  constructor(public as: ApiAngularService, public ns: ApiNativeService, public ps: PlatformService) {
    super(as, ns, ps);
  }

}
