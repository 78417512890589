import {Injectable} from "@angular/core";
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from "@angular/router";
import {AuthService} from "./auth.service.service";

// https://blog.smartcodehub.com/ionic-5-auth-guard-implementation/
// https://jasonwatmore.com/post/2019/06/22/angular-8-jwt-authentication-example-tutorial#auth-guard-ts

@Injectable({
  providedIn: "root",
})
export class AuthGuard implements CanActivate {

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.auth.isLoggedIn) {
      return true;
    }

    this.router.navigate(['/signin'], {queryParams: {returnUrl: state.url}});
    return false;
  }

  constructor(private auth: AuthService, private router: Router) {
  }

}
