import {Component, Injectable, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../base.component";
import {Positions} from "../../model/positions.model";

@Component({
  selector: 'bending-diagram',
  template: `
    <div class="diagram">
      <ion-card>
        <ion-card-header>
          Bending moment and shear force diagram ({{ ips.numspans.value }} spans)
        </ion-card-header>
        <ion-card-content>
          <img src="{{'assets/images/bending/' + ips.numspans.value + 'SPAN Bending and shear force diag.png'}}">
          <bending-moment-table></bending-moment-table>
          <shear-force-table></shear-force-table>
        </ion-card-content>
      </ion-card>
    </div>
  `
})

@Injectable()
export class BendingDiagramComponent extends BaseComponent {

  ngAfterViewInit() {
    this.detectChanges();
  }

}
