import {Component, OnInit, ViewChild} from '@angular/core';
import {IonSlides} from '@ionic/angular';
import {BaseComponent} from "../../comp/base.component";

//https://stackoverflow.com/questions/35266798/100-height-in-ion-slide-box-not-working
//https://github.com/ionic-team/ionic-v3/issues/661

@Component({
  selector: 'app-intro',
  templateUrl: './intro.component.html'
})
export class IntroComponent extends BaseComponent implements OnInit {

  showSplash: boolean = true;

  @ViewChild('slides', {static: false}) slides: IonSlides

  //https://ionicframework.com/docs/api/slides
  //https://swiperjs.com/swiper-api
  slideOpts = {
    initialSlide: 0,
    type: "bullets",
    allowTouchMove: true,
    clickable: true,
    speed: 400,
    autoHeight: false,
    scrollbar: false
  };

  startTour() {
    this.showSplash = false;
  }

  resetTour() {
    this.showSplash = true;
    this.slideOpts.initialSlide = 0;
  }

  onContinue() {
    this.router.navigate(['/signin'])
  }

  onLoggedIn() {
    this.slides.slideNext();
  }

  gotoEstimator() {
    this.ps.gotoEstimator();
  }

  ngOnInit() {
  }

}
