import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../base.component";
import {IonTabs} from '@ionic/angular';

/**
 * See https://stackoverflow.com/questions/35266798/100-height-in-ion-slide-box-not-working
 * https://github.com/ionic-team/ionic-v3/issues/661
 */

@Component({
  selector: 'estimator-mobile',
  template: `

    <ion-tabs #tabs (ionTabsDidChange)="tabChange()">
      <ion-tab-bar slot="top">

        <ion-tab-button tab="input1">
          <ion-icon name="calculator"></ion-icon>
          <ion-label>Input 1</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="input2">
          <ion-icon name="calculator"></ion-icon>
          <ion-label>Input 2</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="input3">
          <ion-icon name="calculator"></ion-icon>
          <ion-label>Input 3</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="defaults">
          <ion-icon name="calculator"></ion-icon>
          <ion-label>Defaults</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="output1-mobile">
          <ion-icon name="podium"></ion-icon>
          <ion-label>Output</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>

  `
})
export class EstimatorMobileComponent extends BaseComponent implements OnInit {
  @ViewChild('tabs') tabs: IonTabs

  ngOnInit() {
    this.onWindowSizeChange.subscribe((result: any) => {
      this.ps.checkResizeEstimatorMobileDesktop(result, this.route);
    });
  }

  ionViewDidEnter() {
    this.tabs.select('input1');
    this.onWindowResize(null, true);
  }

  tabChange() {
    this.ips.calculate();
  }

}
