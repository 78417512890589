import {Component, OnInit, ViewChild} from "@angular/core";
import {BaseComponent} from "../base.component";

import {
  ApexAxisChartSeries,
  ApexChart,
  ApexDataLabels,
  ApexGrid,
  ApexMarkers,
  ApexStroke,
  ApexTitleSubtitle,
  ApexXAxis,
  ApexYAxis,
  ChartComponent
} from "ng-apexcharts";

/**
 * See
 * https://apexcharts.com/docs/angular-charts/
 * https://apexcharts.com/angular-chart-demos/
 * https://www.npmjs.com/package/ng-apexcharts
 *
 */
export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  grid: ApexGrid;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
};

@Component({
  selector: 'span-support-graph',
  template: `
    <div id="chart">
      <apx-chart
        [series]="chartOptions.series"
        [chart]="chartOptions.chart"
        [xaxis]="chartOptions.xaxis"
        [yaxis]="chartOptions.yaxis"
        [dataLabels]="chartOptions.dataLabels"
        [markers]="chartOptions.markers"
        [grid]="chartOptions.grid"
        [stroke]="chartOptions.stroke"
      ></apx-chart>
    </div>
  `
})
export class SpanSupportGraph extends BaseComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  public chartOptions: Partial<ChartOptions>;

  data = [[0, 0], [7.5, 0], [15, 0], [22.5, 0], [22.5, 0]];

  drawChart() {
    this.chartOptions = {
      series: [
        {
          data: this.data
        }
      ],
      chart: {
        height: 100,
        width: '100%',
        type: "scatter",
        zoom: {
          enabled: false
        }
      },
      markers: {
        colors: ["#FFA41B"],
        offsetY: 0,
        width: 15,
        height: 15,
        fillOpacity: 1,
        shape: "rect"
      },
      dataLabels: {
        enabled: false
      },
      grid: {
        xaxis: {
          lines: {show: true}
        },
        yaxis: {
          lines: {show: false}
        },
      },
      xaxis: {
        min: 0,
        max: 25,
        tickAmount: 5,
        axisBorder: {
          show: true,
          color: 'green',
          strokeWidth: 15,
          offsetY: -2
        }
      },
      yaxis: {
        show: false
      }
    };
  }

  ngOnInit() {
    this.drawChart();
  }

  ngAfterViewInit() {
  }

}
