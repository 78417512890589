import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";

@Component({
  selector: 'output1-desktop',
  template: `
    <div *ngIf="!loading" class="output">
      <vp-diagram></vp-diagram>
      <rebar-diagram></rebar-diagram>
      <bending-diagram></bending-diagram>
      <ion-card>
        <ion-card-header>
          Addition values
        </ion-card-header>
        <ion-card-content>
        </ion-card-content>
        <ng-container *ngFor="let element of ips.getOutputTab1()">
          <custom-output [model]="element"></custom-output>
        </ng-container>
      </ion-card>
    </div>
  `
})

export class Output1DesktopComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    this.loading = false;
  }

}
