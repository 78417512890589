import {map} from 'rxjs/operators';
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpParams} from "@angular/common/http";
import {PlatformService} from "../services/platform.service";
import {ApiConfig} from "./api.config";
import {Injectable} from '@angular/core';

/**
 * Use the standard angular http service when running on web browser
 */

@Injectable()
export class ApiAngularService extends ApiConfig {

  private getRequestOptions() {
    return {
      params: new HttpParams(),
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${this.getAuthToken()}`
      }),
      withCredentials: true
    };
  }

  get(url: string, payload: any = {}): Observable<any> {
    return this.http.get(url, this.getRequestOptions()).pipe(map(result => {
      return result;
    }));
  }

  post(url: string, payload: any): Observable<any> {
    return this.http.post(url, JSON.stringify(payload), this.getRequestOptions()).pipe(map(result => {
      return result;
    }));
  }

  constructor(public ps: PlatformService, public http: HttpClient) {
    super();
  }

}
