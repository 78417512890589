import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';
import {AuthGuard} from "./auth/auth.guard";
import {IntroComponent} from "./model/intro/intro.component";
import {ProfileComponent} from "./comp/profile/profile.component";
import {LoginComponent} from "./comp/login/login.component";
import {SignupComponent} from "./comp/signup/signup.component";
import {AboutComponent} from "./comp/about/about.component";
import {AboutAboutComponent} from "./comp/about/about.about.component";
import {AboutPrivacyComponent} from "./comp/about/about.privacy.component";
import {AboutTermsComponent} from "./comp/about/about.terms.component";
import {HelpComponent} from "./comp/about/help.component";
import {EstimatorTestingComponent} from "./comp/secured/estimator.testing.component";
import {EstimatorMobileComponent} from "./comp/secured/estimator.mobile.component";
import {Input1Component} from "./comp/secured/input1.component";
import {Input2Component} from "./comp/secured/input2.component";
import {Input3Component} from "./comp/secured/input3.component";
import {Output1MobileComponent} from "./comp/secured/output1.mobile.component";
import {EstimatorComponent} from "./comp/secured/estimator.component";
import {EstimatorDesktopComponent} from "./comp/secured/estimator.desktop.component";
import {AboutCookiesComponent} from "./comp/about/about.cookies.component";
import {InputDefaultsComponent} from "./comp/secured/input.defaults.component";

const routes: Routes = [
  {path: '', redirectTo: 'intro', pathMatch: 'full'},
  {
    path: 'intro',
    component: IntroComponent
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'estimator',
    component: EstimatorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'estimator-desktop',
    component: EstimatorDesktopComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'input1',
        component: Input1Component
      },
      {
        path: 'input2',
        component: Input2Component
      },
      {
        path: 'input3',
        component: Input3Component
      },
      {
        path: 'defaults',
        component: InputDefaultsComponent
      }

    ]
  },
  {
    path: 'estimator-mobile',
    component: EstimatorMobileComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'input1',
        component: Input1Component
      },
      {
        path: 'input2',
        component: Input2Component
      },
      {
        path: 'input3',
        component: Input3Component
      },
      {
        path: 'defaults',
        component: InputDefaultsComponent
      },
      {
        path: 'output1-mobile',
        component: Output1MobileComponent
      }
    ]
  },
  {
    path: 'about',
    component: AboutComponent,
    children: [
      {
        path: 'about',
        component: AboutAboutComponent
      },
      {
        path: 'privacy',
        component: AboutPrivacyComponent
      },
      {
        path: 'terms',
        component: AboutTermsComponent
      },
      {
        path: 'cookies',
        component: AboutCookiesComponent
      },
      {
        path: '',
        redirectTo: '/about/about',
        pathMatch: 'full'
      }
    ]
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: 'signin',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'estimator-testing',
    component: EstimatorTestingComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {preloadingStrategy: PreloadAllModules, enableTracing: false, useHash: true})
  ],
  exports: [RouterModule]
})

export class AppRoutingModule {
}
