import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";

/**
 * See https://stackoverflow.com/questions/35266798/100-height-in-ion-slide-box-not-working
 * https://github.com/ionic-team/ionic-v3/issues/661
 */

@Component({
  selector: 'estimator',
  template: `
  `
})
export class EstimatorComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    this.onWindowSizeChange.subscribe((result: any) => {
      this.ps.checkResizeEstimatorMobileDesktop(result, this.route);
    });
  }

  ionViewDidEnter() {
    this.onWindowResize(null, true);
  }

}
