import {Component} from "@angular/core";
import {BaseComponent} from "../base.component";
import {ApiResult} from "../../api/api.result.model";

@Component({
  template: `

    <div fxFlexFill fxLayout="column" fxLayoutAlign="start center" class="profile fill-width">

      <div class="header">
        <div class="avatar ion-text-center">
          <ion-icon class="ion-padding-top" name="person-circle-outline"></ion-icon>
        </div>
      </div>

      <div class="ion-text-center">
        <h4 class="name">{{auth.fullName}}</h4>
        <h6 class="email">{{auth.email}}</h6>
      </div>

      <ion-item class="ion-padding-top fill-width">
        <ion-label>Subscribe to marketing emails.</ion-label>
        <ion-toggle [(ngModel)]="subscribed" (ngModelChange)="onSubscribedChanged()" default=""></ion-toggle>
      </ion-item>

      <ion-item class="ion-padding-top fill-width">
        <ion-label>Sign me out</ion-label>
        <ion-button (click)="logout()" color="orange" size="default" class="button ion-align-self-center">Sign Out
        </ion-button>
      </ion-item>

      <ion-item class="ion-padding-top fill-width">
        <ion-label>Delete my account</ion-label>
        <ion-button (click)="promptDeleteAccount()" color="orange" size="default" class="button ion-align-self-center">
          Delete
        </ion-button>
      </ion-item>

    </div>

  `
})

export class ProfileComponent extends BaseComponent {

  private subscribed = false;

  onSubscribedChanged() {
    this.save();
  }

  save() {
    this.api.apiPost('update_profile', {subscribed: this.subscribed}).subscribe((result) => {
      let apiResult = new ApiResult(result);
      if (apiResult.valid) {
      } else {
        this.as.basicAlert('Oops', '', apiResult.responseObject);
      }
    });
  }

  promptDeleteAccount() {
    this.as.alertDeleteAccount().subscribe((del: boolean) => {
      if (del) {
        this.api.apiPost('delete_account', {}).subscribe((result) => {
          let apiResult = new ApiResult(result);
          if (apiResult.valid) {
            this.as.basicAlert(apiResult.responseObject);
            this.auth.logoutUser();
            this.router.navigate(['/intro']);
          } else {
            this.as.basicAlert('Oops', '', apiResult.responseObject);
          }
        });
      }
    });
  }

  logout() {
    this.auth.logoutUser();
    this.router.navigate(['/intro']);
  }

  ngOnInit() {
  }

  ionViewDidEnter() {
    this.api.apiPost('get_profile', {}).subscribe((result) => {
      let apiResult = new ApiResult(result);
      if (apiResult.valid) {
        this.subscribed = apiResult.responseObject['subscribe_marketing'];
        this.loading = false;
      }
    })
  }

}
