import {Component, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from "../base.component";
import {ApiResult} from "../../api/api.result.model";

//https://edupala.com/ionic-reactive-form-validation/
//https://www.techiediaries.com/ionic-ui-forms-theming/

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
})
export class SignupComponent extends BaseComponent implements OnInit {

  form: FormGroup;

  signUp() {

    this.fs.markFieldsDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    this.ls.simpleLoading();
    this.api.signupPost({
      name: this.form.controls.name.value,
      nickname: this.form.controls.nickname.value,
      email: this.form.controls.email.value,
      phone: this.form.controls.phone.value,
      password: this.form.controls.password.value
    }).subscribe((result) => {
      this.ls.dismissLoader();
      let apiResult = new ApiResult(result);
      if (!apiResult.valid) {
        this.as.basicAlert('Signup failed', '', apiResult.responseObject);
      } else {
        this.as.basicAlert('Signup successful');
        this.router.navigate(['/signin']);
      }
    }, (error) => {
      this.ls.dismissLoader();
      alert(`${error.name} (${error.message})`);
    });
  }

  get errorCtr() {
    return this.form.controls;
  }

  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';

  showHidePassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  reset() {
    this.form.reset();
  }

  ngOnInit() {
    this.form = this.fb.group({
      name: [null, [Validators.required]],
      nickname: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });
  }

}
