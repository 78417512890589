import {Component, OnInit, ViewChild} from '@angular/core';
import {BaseComponent} from "../base.component";
import {CalculationService} from "../../external/calculation.service";
import {IonTabs} from '@ionic/angular';

/**
 * See https://ionicframework.com/docs/api/grid
 */

@Component({
  selector: 'estimator-desktop',
  template: `
    <ion-grid [fixed]="true">
      <ion-row class="estimator-desktop">
        <ion-col>
          <ion-tabs #tabs (ionTabsDidChange)="tabChange()">
            <ion-tab-bar slot="top">

              <ion-tab-button tab="input1">
                <ion-icon name="calculator"></ion-icon>
                <ion-label>Input 1</ion-label>
              </ion-tab-button>

              <ion-tab-button tab="input2">
                <ion-icon name="calculator"></ion-icon>
                <ion-label>Input 2</ion-label>
              </ion-tab-button>

              <ion-tab-button tab="input3">
                <ion-icon name="calculator"></ion-icon>
                <ion-label>Input 3</ion-label>
              </ion-tab-button>

              <ion-tab-button tab="defaults">
                <ion-icon name="calculator"></ion-icon>
                <ion-label>Defaults</ion-label>
              </ion-tab-button>

            </ion-tab-bar>
          </ion-tabs>

        </ion-col>
        <ion-col>

          <div class="output-desktop">
            <ion-accordion-group value="first">
              <ion-accordion value="first">
                <ion-item slot="header" color="light">
                  <ion-label>Output 1</ion-label>
                </ion-item>
                <div class="ion-padding" slot="content" class="accordion-content ">
                  <output1-desktop></output1-desktop>
                </div>
              </ion-accordion>
              <ion-accordion>
                <ion-item slot="header" color="light">
                  <ion-label>Output 2</ion-label>
                </ion-item>
                <div class="ion-padding" slot="content" class="accordion-content ">
                  <output2-desktop></output2-desktop>
                </div>
              </ion-accordion>
              <ion-accordion>
                <ion-item slot="header" color="light">
                  <ion-label>Output 3</ion-label>
                </ion-item>
                <div class="ion-padding" slot="content" class="accordion-content ">
                  <output3-desktop></output3-desktop>
                </div>
              </ion-accordion>
            </ion-accordion-group>
          </div>

        </ion-col>
      </ion-row>
    </ion-grid>
  `
})
export class EstimatorDesktopComponent extends BaseComponent implements OnInit {

  @ViewChild('tabs') tabs: IonTabs
  calc: CalculationService = new CalculationService();
  showOutput = false;

  ngOnInit() {
    this.onWindowSizeChange.subscribe((result: any) => {
      this.ps.checkResizeEstimatorMobileDesktop(result, this.route);
    });
  }

  onChange(event) {
    this.ips.calculate();
    this.showOutput = true;
  }

  ionViewDidEnter() {
    this.tabs.select('input1');
    this.onWindowResize(null, true);
  }

  tabChange() {
    this.ips.calculate();
  }

}
