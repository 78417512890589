import {Component} from "@angular/core";
import {BaseComponent} from "../base.component";

@Component({
  template: `

      <ion-content class="ion-padding about">
          <div class="page-heading">
              Help
          </div>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam aliquam pellentesque tortor at volutpat.
          Donec
          at dapibus neque, sit amet pretium quam. Sed lacinia massa ac leo lobortis consequat. Donec semper malesuada
          eros eu semper. Quisque eu massa feugiat neque porta fringilla. Etiam congue eros augue, ac cursus elit
          euismod
          sed.
      </ion-content>>
  `
})
export class HelpComponent extends BaseComponent {
}
