export class SelectOption {
  label: number | string;
  value: number | string;

  constructor(label: number | string, value: number | string) {
    this.label = label;
    this.value = value;
  }

}
