import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";
import {CalculationService} from "../../external/calculation.service";

//use for testing purposes so that we can see all the input params and output data on a single web page

@Component({
  selector: 'estimator-testing',
  template: `
    <ion-content>
      <div class="testing">

        <ion-accordion-group>
          <ion-accordion>
            <ion-item slot="header" color="light">
              <ion-label>Input 1</ion-label>
            </ion-item>
            <div class="ion-padding" slot="content">
              <input1 (onChange)="onChange($event)"></input1>
            </div>
          </ion-accordion>

          <ion-accordion>
            <ion-item slot="header" color="light">
              <ion-label>Input 2</ion-label>
            </ion-item>
            <div class="ion-padding" slot="content">
              <input2 (onChange)="onChange($event)"></input2>
            </div>
          </ion-accordion>

          <ion-accordion>
            <ion-item slot="header" color="light">
              <ion-label>Input 3</ion-label>
            </ion-item>
            <div class="ion-padding" slot="content">
              <input3 (onChange)="onChange($event)"></input3>
            </div>
          </ion-accordion>

          <ion-accordion>
            <ion-item slot="header" color="light">
              <ion-label>Input 4</ion-label>
            </ion-item>
            <div class="ion-padding" slot="content">
              <input4 (onChange)="onChange($event)"></input4>
            </div>
          </ion-accordion>

        </ion-accordion-group>

        <debug-table1 *ngIf="showOutput" [data]="ips.positions"></debug-table1>
        <output1></output1>
      </div>
    </ion-content>
  `
})
export class EstimatorTestingComponent extends BaseComponent implements OnInit {

  calc: CalculationService = new CalculationService();
  showOutput = false;

  ngOnInit() {
  }

  onChange(event) {
    let output = this.calc.calculate(this.ips.asCalcInput());
    this.showOutput = true;
  }

}
