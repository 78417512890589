import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";

@Component({
  selector: 'input3',
  template: `
      <div *ngIf="!loading" class="input">
          <ng-container *ngFor="let element of ips.getElementsTab3()">
              <custom-input-view [model]="element"></custom-input-view>
          </ng-container>
      </div>
  `
})

export class Input3Component extends BaseComponent implements OnInit {
  ngOnInit() {
    this.loading = false;
  }

}
