import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {BaseComponent} from "../base.component";
import {FormGroup} from '@angular/forms';
import {InputElement} from "../../model/input.element.model";

/**
 * See https://ionicframework.com/docs/api/modal
 * https://edupala.com/ionic-modal-controller-exmple/
 */

@Component({
  selector: 'modal-input',
  template: `
    <ion-header>
      <ion-toolbar color="tertiary">
        <ion-buttons slot="end">
        </ion-buttons>
        <ion-title [innerHTML]="_cloned.label"></ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <form [formGroup]="form" novalidate>
        <div *ngIf="!loading">
          <ion-row class="ion-justify-content-center">
            <ion-col size-lg="6" size-sm="10">
              <custom-input [form]="form" [model]="_cloned" (onValidChange)="onValidChange($event)"></custom-input>
              <br/>
              <h2>Description</h2>
              <div [innerHTML]="_original.description"></div>
              <ng-container *ngIf="_original.min">
                <h2>Range</h2>
                Minimum: {{ _original.min }}<br>
                Maximum: {{ _original.max }}
              </ng-container>
            </ion-col>
          </ion-row>

        </div>
      </form>
    </ion-content>
    <ion-footer>
      <ion-toolbar>
        <div class="ion-float-right ion-padding-end">
          <ion-button size="default" class="ion-padding-end " fill="outline" (click)="cancel()">Cancel</ion-button>
          <ion-button fill="solid" color="primary" [disabled]="!isFormValid" (click)="save()">Save</ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
  `
})

export class CustomInputModal extends BaseComponent implements OnInit {
  presentingElement = null;

  form: FormGroup;

  isFormValid: boolean = true;

  _cloned: InputElement;
  _original: InputElement;

  @Output() onChange = new EventEmitter();

  @Input() set model(val: InputElement) {
    this._original = val;
    this._cloned = Object.assign({}, val);
    this.buildForm();
  }

  onValidChange(isValid: boolean) {
    this.isFormValid = isValid;
  }

  buildForm() {
    this.form = this.fb.group({});
    this.ips.addFormFields(this.form, [this._cloned]);
    this.loading = false;
  }

  ngOnInit() {
    this.presentingElement = document.querySelector('.ion-page');
  }

  cancel() {
    this.modalController.dismiss(null, 'cancel');
  }

  save() {
    this._original.value = this._cloned.value;
    this.modalController.dismiss({model: this._original}, 'confirm');
  }

}
