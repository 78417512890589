import {Component, Injectable, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../base.component";
import {Positions} from "../../model/positions.model";

@Component({
  selector: 'debug-table1',
  template: `
    <div class="output-table debug-table">
      <h4>Debug: Based on pattern loading</h4>
      <table>
        <thead>
        <th>Span</th>
        <th>Pos</th>
        <th>Show</th>
        <th>Position</th>
        <th>m1</th>
        <th>m2</th>
        <th>m3</th>
        <th>m4</th>
        <th>s1</th>
        <th>s2</th>
        <th>s3</th>
        <th>s4</th>
        <th>Distance</th>
        <th>Moment</th>
        <th>Shear</th>
        </thead>
        <ng-container *ngFor="let item of data | keyvalue">
          <tr>
            <td>
              {{item.value.spanNo}}
            </td>
            <td>
              {{item.value.code}}
            </td>
            <td>
              {{item.value.display}}
            </td>
            <td>
              {{item.value.position | number : '1.1-1'}}
            </td>
            <td *ngFor="let m of item.value.moments">{{m | number : '1.1-1'}}</td>
            <td *ngFor="let s of item.value.shears">{{s | number : '1.1-1'}}</td>
            <td>
              {{item.value.selectedDistance | number : '1.1-1'}}
            </td>
            <td>
              {{item.value.selectedMoment | number : '1.1-1'}}
            </td>
            <td>
              {{item.value.selectedShear | number : '1.1-1'}}
            </td>
          </tr>
        </ng-container>
      </table>
    </div>
  `
})

@Injectable()
export class DebugTable1Component extends BaseComponent implements OnInit {

  @Input() data: Positions;

  ngOnInit(): void {
  }

}
