import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";

@Component({
  selector: 'output2-desktop',
  template: `
    <div *ngIf="!loading" class="output">
      <calculation-messages [messages]="ips.output.messages"></calculation-messages>
    </div>
  `
})

export class Output2DesktopComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    this.loading = false;
  }

}
