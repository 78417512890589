import {Component} from "@angular/core";
import {BaseComponent} from "../base.component";

@Component({
  template: `

      <ion-content class="ion-padding about">
          <div class="page-heading">
              Cookie Policy
          </div>

          <ol>
              <li>Introduction</li>
              This Cookie Policy explains how we use cookies and similar technologies on our website and application. By using
              our website or and application, you consent to the use of cookies as described in this policy.

              <li>What are Cookies?</li>

              Cookies are small text files that are placed on your computer or device when you visit a website.
              They are widely used to make websites work more efficiently and to provide information to the website
              owner.

              <li>Types of Cookies We Use</li>

              <ul>
                  <li>Essential Cookies</li>
                  These cookies are necessary for the website and application to function properly. They enable core functionality such
                  as security, network management, and accessibility. You may disable these by changing your browser
                  settings, but this may affect how the website and application functions.

                  <li>Analytics Cookies</li>
                  These cookies help us understand how visitors interact with our website and application by collecting and
                  reporting information anonymously. We use this information to improve our application and enhance the user
                  experience.

              </ul>

              <li>How to Manage Cookies</li>

              You can control and/or delete cookies as you wish. For details, see
              <a href="https://www.allaboutcookies.org/" target="_blank">www.allaboutcookies.org</a>. You can
              delete all cookies that are already on your computer, and you can set most browsers to prevent
              them from being placed. If you do this, however, you may have to manually adjust some preferences
              every time you visit a site, and some services and functionalities may not work.

              <li>Consent to Use Cookies</li>

              By using our website or application, you consent to the use of cookies as described in this Cookie Policy. If you
              do not agree to the use of cookies, please disable or block them in your browser settings.

              <li>Changes to This Cookie Policy</li>

              We may update our Cookie Policy from time to time. Any changes will be posted on this page, so
              please review it periodically.


              <li>Contact Us</li>
              If you have any questions about our Cookie Policy, please contact us at
              <a href="query@voidcon.com">query@voidcon.com</a>.

          </ol>

          <br/>

          <strong>Last Updated: </strong>2023-12-04

      </ion-content>

  `
})
export class AboutCookiesComponent extends BaseComponent {
}
