import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, Validators} from '@angular/forms';
import {BaseComponent} from "../base.component";
import {ApiResult} from "../../api/api.result.model";

//https://edupala.com/ionic-reactive-form-validation/
//https://www.techiediaries.com/ionic-ui-forms-theming/

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
})
export class LoginComponent extends BaseComponent implements OnInit {

  form: FormGroup;
  returnUrl: string;

  @Input() onLoggedIn: Function;

  login() {

    this.fs.markFieldsDirty(this.form);

    if (!this.form.valid) {
      return;
    }

    this.ls.simpleLoading();
    this.auth.loginUser(this.form.controls.email.value, this.form.controls.password.value).subscribe((apiResult: ApiResult) => {
      this.ls.dismissLoader();
      if (!apiResult.valid) {
        this.as.basicAlert('Login failed', '', apiResult.responseObject);
      } else {
        //this.as.basicAlert('Login successful');

        if (this.onLoggedIn) {
          this.onLoggedIn();
        } else {
          let navigateTo = this.returnUrl ? this.returnUrl : '/estimator';
          this.router.navigate([navigateTo]);
        }

      }
    });
  }

  testLoggedIn() {
    this.api.apiGet('testing', {}).subscribe((result) => {
      let apiResult = new ApiResult(result);
      if (apiResult.valid) {
        alert('logged in, result: ' + apiResult.responseObject);
      } else {
        alert('not logged in');
      }
    }, (error) => {
      if (error.status = 401) {
        alert('401 not logged in');
      } else {
        alert('error: ' + JSON.stringify(error));
      }
    });
  }

  get errorCtr() {
    return this.form.controls;
  }

  passwordType: string = 'password';
  passwordIcon: string = 'eye-off';

  showHidePassword() {
    this.passwordType = this.passwordType === 'text' ? 'password' : 'text';
    this.passwordIcon = this.passwordIcon === 'eye-off' ? 'eye' : 'eye-off';
  }

  reset() {
    this.form.reset();
  }

  ngOnInit() {
    this.form = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]]
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'];

  }

}
