import {Injectable} from "@angular/core";
import {LoadingController} from "@ionic/angular";

// https://www.positronx.io/ionic-loading-controller-tutorial-with-ion-loading-example/

@Injectable({
  providedIn: 'root'
})

export class LoadingService {

  isLoading = false;

  // Simple loader
  simpleLoading() {
    this.isLoading = true;
    this.loadingCtrl.create({
      cssClass: 'simple-loading',
      message: 'Please wait...'
    }).then((response) => {
      response.present().then(() => {
        if (!this.isLoading) {
          response.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
  }

  dismissLoader() {
    this.isLoading = false;
    this.loadingCtrl.dismiss().then((response) => {
    }).catch((error) => {
      console.log('Error occurred : ', error);
    });
  }

  // Hide after n seconds
  autoLoader() {
    this.loadingCtrl.create({
      message: 'Loader hides after 4 seconds',
      duration: 4000
    }).then((response) => {
      response.present();
      response.onDidDismiss().then((response) => {
      });
    });
  }

  // Custom style + hide on tap loader
  customLoader() {
    this.loadingCtrl.create({
      message: 'Loader with custom style',
      duration: 4000,
      cssClass: 'loader-css-class',
      backdropDismiss: true
    }).then((res) => {
      res.present();
    });
  }

  constructor(public loadingCtrl: LoadingController) {
  }

}
