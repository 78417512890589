import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouteReuseStrategy} from '@angular/router';
import {IonicModule, IonicRouteStrategy,} from '@ionic/angular';
import {AppComponent} from './app.component';
import {ApiService} from "./api/api.service";
import {HttpClientModule} from '@angular/common/http';
import {StatusBar} from '@ionic-native/status-bar/ngx';
import {Network} from '@ionic-native/network/ngx';
import {AppRoutingModule} from "./app.routing.module";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FlexLayoutModule} from '@angular/flex-layout';
import {AuthService} from "./auth/auth.service.service";
import {LoadingService} from "./services/loading.service";
import {AlertService} from "./services/alert.service";
import {PlatformService} from "./services/platform.service";
import {FormService} from "./services/form.service";
import {HTTP} from '@ionic-native/http/ngx';
import {ApiNativeService} from "./api/api.native.service";
import {ApiAngularService} from "./api/api.angular.service";
import {ProfileComponent} from "./comp/profile/profile.component";
import {MenuItemComponent} from "./comp/navigation/menu.item.component";
import {MainMenuComponent} from "./comp/navigation/main.menu.component";
import {SignupComponent} from "./comp/signup/signup.component";
import {LoginComponent} from "./comp/login/login.component";
import {IntroComponent} from "./model/intro/intro.component";
import {AboutComponent} from "./comp/about/about.component";
import {AboutAboutComponent} from "./comp/about/about.about.component";
import {AboutPrivacyComponent} from "./comp/about/about.privacy.component";
import {AboutTermsComponent} from "./comp/about/about.terms.component";
import {HelpComponent} from "./comp/about/help.component";
import {Input1Component} from "./comp/secured/input1.component";
import {Input2Component} from "./comp/secured/input2.component";
import {CustomInputComponent} from "./comp/input/custom.input.component";
import {Input3Component} from "./comp/secured/input3.component";
import {ProjectsListComponent} from "./comp/projects/projects.list.component";
import {CustomOutputComponent} from "./comp/input/custom.output.component";
import {ParamsService} from "./services/params.service";
import {EstimatorTestingComponent} from "./comp/secured/estimator.testing.component";
import {DebugTable1Component} from "./comp/debug/debug.table1.component";
import {AreaSteelTableComponent} from "./comp/output/area.steel.table.component";
import {CalculationService} from "./external/calculation.service";
import {NgApexchartsModule} from "ng-apexcharts";
import {SpanSupportGraph} from "./comp/output/span.support.graph";
import {EstimatorMobileComponent} from "./comp/secured/estimator.mobile.component";
import {EstimatorDesktopComponent} from "./comp/secured/estimator.desktop.component";
import {EstimatorComponent} from "./comp/secured/estimator.component";
import {Output1MobileComponent} from "./comp/secured/output1.mobile.component";
import {CustomInputModal} from "./comp/input/custom.input.modal";
import {CustomInputViewComponent} from "./comp/input/custom.input.view.component";
import {CalculationMessagesComponent} from "./comp/output/calculation.messages";
import {Output2DesktopComponent} from "./comp/secured/output2.desktop.component";
import {Output1DesktopComponent} from "./comp/secured/output1.desktop.component";
import {Output3DesktopComponent} from "./comp/secured/output3.desktop.component";
import {AboutCookiesComponent} from "./comp/about/about.cookies.component";
import {InputDefaultsComponent} from "./comp/secured/input.defaults.component";
import {BendingDiagramComponent} from "./comp/diagram/bending.diagram.component";
import {RebarDiagramComponent} from "./comp/diagram/rebar.diagram.component";
import {VpDiagramComponent} from "./comp/diagram/vp.diagram.component";
import {BendingMomentTableComponent} from "./comp/output/bending.moment.table.component";
import {EnumeratePipe} from "./pipes/enumerate.pipe";
import {ShearForceTableComponent} from "./comp/output/shear.force.table.component";
import {RebarSpecificationTableComponent} from "./comp/output/rebar.specification.table.component";

@NgModule({
  declarations: [
    AppComponent,
    AboutComponent,
    AboutAboutComponent,
    AboutPrivacyComponent,
    AboutTermsComponent,
    AboutCookiesComponent,
    HelpComponent,
    ProfileComponent,
    MenuItemComponent,
    MainMenuComponent,
    IntroComponent,
    SignupComponent,
    LoginComponent,
    EstimatorTestingComponent,
    CustomInputComponent,
    CustomInputViewComponent,
    CustomInputModal,
    CustomOutputComponent,
    ProjectsListComponent,
    EstimatorComponent,
    EstimatorMobileComponent,
    EstimatorDesktopComponent,
    Input1Component,
    Input2Component,
    Input3Component,
    InputDefaultsComponent,
    Output1MobileComponent,
    Output1DesktopComponent,
    Output2DesktopComponent,
    Output3DesktopComponent,
    DebugTable1Component,
    AreaSteelTableComponent,
    BendingMomentTableComponent,
    ShearForceTableComponent,
    RebarSpecificationTableComponent,
    SpanSupportGraph,
    CalculationMessagesComponent,
    BendingDiagramComponent,
    RebarDiagramComponent,
    VpDiagramComponent,
    EnumeratePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgApexchartsModule,
  ],
  entryComponents: [],
  providers: [
    StatusBar,
    {provide: RouteReuseStrategy, useClass: IonicRouteStrategy},
    HTTP,
    Network,
    AuthService,
    ApiNativeService,
    ApiAngularService,
    ApiService,
    LoadingService,
    AlertService,
    PlatformService,
    FormService,
    ParamsService,
    CalculationService
  ],
  bootstrap: [
    AppComponent
  ]

})

export class AppModule {
}
