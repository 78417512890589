import {Component, Injectable, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../base.component";
import {Positions} from "../../model/positions.model";

@Component({
  selector: 'rebar-diagram',
  template: `
    <div class="diagram">
      <ion-card>
        <ion-card-header>
          Span and rebar layout diagram ({{ ips.numspans.value }} spans)
        </ion-card-header>
        <ion-card-content>
          <img src="{{'assets/images/rebar/' + ips.numspans.value + 'SPAN Rebar and layout.png'}}">
          <span>L = {{ips.spanlength.value}}m</span>
          <rebar-specification-table></rebar-specification-table>
        </ion-card-content>
      </ion-card>
    </div>
  `
})

@Injectable()
export class RebarDiagramComponent extends BaseComponent {

  ngAfterViewInit() {
    this.detectChanges();
  }

}
