import {Component, EventEmitter, Injectable, Input, OnInit, Output} from "@angular/core";
import {BaseComponent} from "../base.component";
import {InputElement} from "../../model/input.element.model";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'custom-input',
  template: `
    <form [formGroup]="form" novalidate>
      <ion-item class="edit-item">
        <ion-input *ngIf="_model.type == 'number'" type="number" [(ngModel)]="_model.value" (ionChange)='onInputChange($event.target.value)' [formControlName]="_model.code" [name]="_model.code" [readonly]="_model.readonly" class="input" inputmode="numeric" [min]="_model.min" [max]="_model.max" [step]="_model.step" [title]="_model.code"></ion-input>
        <ion-select *ngIf="_model.type == 'select'" placeholder="Select" [(ngModel)]="_model.value" (ionChange)='onInputChange($event.target.value)' [formControlName]="_model.code" [name]="_model.code" class="input" interface="ion-action-sheet" [title]="_model.code">
          <ion-select-option *ngFor="let option of _model.options" [value]="option.value">{{ option.label }}</ion-select-option>
        </ion-select>
      </ion-item>
    </form>
  `
})

@Injectable()
export class CustomInputComponent extends BaseComponent implements OnInit {

  _model: InputElement;

  isFormValid: boolean;

  @Input() form: FormGroup;
  @Output() onValidChange = new EventEmitter<boolean>();

  set model(model: InputElement) {
    this._model = model;
  }

  @Input() get model(): InputElement {
    return this._model;
  }

  onInputChange(e) {
    this.isFormValid = this.form.valid;
    this.onValidChange.next(this.isFormValid);
  }

  ngOnInit(): void {
  }

}
