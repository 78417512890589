import {Component} from "@angular/core";
import {BaseComponent} from "../base.component";

@Component({
  template: `

    <ion-tabs>
      <ion-tab-bar slot="top">

        <ion-tab-button tab="about">
          <ion-icon name="information-circle"></ion-icon>
          <ion-label>About</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="privacy">
          <ion-icon name="briefcase"></ion-icon>
          <ion-label>Privacy</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="terms">
          <ion-icon name="warning"></ion-icon>
          <ion-label>Disclaimer</ion-label>
        </ion-tab-button>

        <ion-tab-button tab="cookies">
          <ion-icon name="download"></ion-icon>
          <ion-label>Cookies</ion-label>
        </ion-tab-button>

      </ion-tab-bar>
    </ion-tabs>
  `
})
export class AboutComponent extends BaseComponent {
}
