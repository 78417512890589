import {Component, Injectable, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../base.component";
import {Positions} from "../../model/positions.model";

@Component({
  selector: 'shear-force-table',
  template: `
    <div *ngIf="!loading" class="output-table">
      <h4>Shear force (kN)</h4>
      <table>
        <thead>
        <th>Span</th>
        <th>Left support</th>
        <th>Right support</th>
        </thead>
        <ng-container *ngFor="let span of ips.numspans.valueAsNumber | enumerate">
          <tr>
            <td class="col1">{{ rows[span][0] }}</td>
            <td class="col2">{{ rows[span][1]  | number : '1.1-1' }}</td>
            <td class="col3">{{ rows[span][2]  | number : '1.1-1' }}</td>
          </tr>
        </ng-container>
      </table>
    </div>
  `
})

@Injectable()
export class ShearForceTableComponent extends BaseComponent implements OnInit {

  forces: any;
  rows: string[][] = [];

  setData() {
    this.loading = true;
    this.rows = [];
    this.forces = this.ips.output.Vu;
    let spanCount = this.ips.numspans.valueAsNumber;
    let n = 0;
    for (let i = 0; i < spanCount; i++) {
      this.rows[i] = [];
      if (i < 3) {
        this.rows[i][0] = (i + 1).toString();
      } else {
        this.rows[i][0] = '4 or more';
      }
      this.rows[i][1] = this.forces[n++];
      this.rows[i][2] = this.forces[n++];
    }
    this.loading = false;
  }

  ngOnInit(): void {
    this.ips.onInputChanged.subscribe((model) => {
      this.setData();
    });
  }

}
