import { Subject } from "rxjs";
import {IbaseModel} from "./ibase.model";
import {SelectOption} from "./select.option.model";

export class InputElement implements IbaseModel {

  onSetValue: Subject<number | string > = new Subject();

  code: string;
  label: string;
  type: string;
  min: number;
  max: number;
  step: number;
  defaultValue: number | string = null;
  value: number | string;
  options: Array<SelectOption>;               // if dropdown select
  selectedOption: SelectOption = null;        // if dropdown select
  description: string;                        // may contain html
  readonly: boolean = false;
  _showSelectedOption: boolean = false;       // show the label of the selected option instead of the actual numerical value

  // show the label of the selected option instead of the value
  setShowSelectedOption(showSelectedOption: boolean) {
    this._showSelectedOption = showSelectedOption;
    return this;
  }

  get isSelect() {
    return this.options.length > 0;
  }

  get valueAsNumber(): number {
    return Number(this.value);
  }

  setValue(value: number | string) {
    this.onSetValue.next(value);
  }
  setSelectedOption() {
    let matches = this.options.filter(option => option.value == this.value);
    if (matches) {
      this.selectedOption = matches[0];
    } else {
      this.selectedOption = null;
    }
  }

  constructor(code: string, label: string, type: string, min: number, max: number, step: number, defaultValue: number | string, options: Array<SelectOption>, description, readonly: boolean = false) {
    this.code = code;
    this.label = label;
    this.type = type;
    this.min = min;
    this.max = max;
    this.step = step;
    this.defaultValue = defaultValue;
    this.options = options;
    this.description = description;
    this.value = defaultValue;
    this.readonly = readonly;
    if (options.length > 0) {
      this.setSelectedOption();
    }
    return this;
  }

  fromOther(other: any) {
    return this;
  }

}
