import {Component} from "@angular/core";
import {BaseComponent} from "../base.component";

@Component({
  template: `

      <ion-content class="ion-padding about">
          <div class="page-heading">
              Disclaimer Policy
          </div>

          <p>
              This civil engineering application is provided "as is" without any representations or warranties, express
              or implied. Void Pro Manufacturing makes no representations or warranties in relation to this application
              or the
              information and materials provided within the application.
          </p>

          <p>
              Void Pro Manufacturing does not warrant that the application will be constantly available, or available at
              all, or
              that the information on the application is complete, true, accurate, or non-misleading.
          </p>

          <p>
              Nothing in this application disclaimer will exclude or limit any warranty implied by law that it would be
              unlawful to exclude or limit; and nothing in this application disclaimer will exclude or limit Void Pro
              Manufacturing's liability in respect of any:
          </p>

          <ol>
              <li>Death or personal injury caused by Void Pro Manufacturing's negligence;</li>
              <li>Fraud or fraudulent misrepresentation on the part of Void Pro Manufacturing; or</li>
              <li>Matter which it would be illegal or unlawful for Void Pro Manufacturing to exclude or limit, or to
                  attempt or
                  purport to exclude or limit, its liability.
              </li>
          </ol>

          <p>
              By using this application, you agree that the exclusions and limitations of liability set out in this
              application disclaimer are reasonable.
          </p>

          <p>
              If you do not think they are reasonable, you must not use this application.
          </p>

          <p>
              You accept that, as a limited liability entity, Void Pro Manufacturing has an interest in limiting the
              personal
              liability of its officers and employees. You agree that you will not bring any claim personally against
              Void Pro Manufacturing's officers or employees in respect of any losses you suffer in connection with the
              application.
          </p>

          <p>
              Without prejudice to the foregoing paragraph, you agree that the limitations of warranties and liability
              set
              out in this application disclaimer will protect Void Pro Manufacturing's officers, employees, agents,
              subsidiaries,
              successors, assigns, and sub-contractors.
          </p>

          <p>
              If any provision of this application disclaimer is, or is found to be, unenforceable under applicable law,
              that will not affect the enforceability of the other provisions of this application disclaimer.
          </p>

      </ion-content>
  `
})
export class AboutTermsComponent extends BaseComponent {
}
