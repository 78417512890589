import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";

@Component({
  selector: 'output3-desktop',
  template: `
    <div *ngIf="!loading" class="output">
      <textarea style="height:400px;font-size: 80%;font-family: 'Courier New'" [innerHtml]="ips.output | json" class="json"></textarea>

    </div>
  `
})

export class Output3DesktopComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    this.loading = false;
  }

}
