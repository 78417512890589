import {Component, Injectable, Input, OnInit} from "@angular/core";
import {BaseComponent} from "../base.component";
import {Positions} from "../../model/positions.model";

@Component({
  selector: 'vp-diagram',
  template: `
    <div class="diagram">
      <ion-card *ngIf="ips.selectedVpSection && ips.selectedWebSection">
        <ion-card-header>
          VP profile cross-section ({{ips.selectedVpSection.vProfile}})
        </ion-card-header>
        <ion-card-content>
          <img src="{{'assets/images/vp/' + ips.selectedVpSection.vProfile + '.png'}}">
          <span>
            h = {{ ips.input.h }}mm,
            h<sub>f</sub> = {{ ips.input.h_f }}mm,
            b<sub>f</sub> = {{ ips.input.b_f }}mm,
            b<sub>b</sub> = {{ ips.input.b_b }}mm</span>
        </ion-card-content>
      </ion-card>
    </div>
  `
})

@Injectable()
export class VpDiagramComponent extends BaseComponent {

  ngAfterViewInit() {
    this.detectChanges();
  }

}
