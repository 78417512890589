import {Component, OnInit} from '@angular/core';
import {BaseComponent} from "../base.component";

@Component({
  selector: 'input4',
  template: `
    <div *ngIf="!loading" class="input">
      <ng-container *ngFor="let element of ips.getElementsDefaultsTab()">
        <custom-input-view [model]="element"></custom-input-view>
      </ng-container>
    </div>
  `
})

export class InputDefaultsComponent extends BaseComponent implements OnInit {

  ngOnInit() {
    this.loading = false;
  }

}
